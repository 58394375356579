import React from 'react';

import { MerchantRegistrationContextProvider } from 'bundles/contexts/MerchantRegistrationContext/MerchantRegistrationContextProvider';
import MerchantRegistrationSteps from 'bundles/components/public/merchantRegistration/MerchantRegistrationSteps';

const AdminMerchantBankAccountsView = () =>
  <MerchantRegistrationContextProvider>
    <MerchantRegistrationSteps />
  </MerchantRegistrationContextProvider>;

export default  AdminMerchantBankAccountsView;
