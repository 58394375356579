import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

import ForgotPasswordForm from 'bundles/components/public/forgotPassword/ForgotPasswordForm';
import { Alert } from 'react-bootstrap';
import { LoginViewProps } from 'bundles/types';
import If from 'bundles/components/common/If';

const ForgotPasswordView = (props: LoginViewProps) => {
  const { t } = useTranslation();

  return (
    <div className="sign-in">
      <If condition={props.inline_message}>
        <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
      </If>

      <Card>
        <Card.Header as={'h1'} className="border-0">{t('titles.forgot_password') as string}</Card.Header>
        <Card.Body>
          <Alert variant="info">{t('messages.help.reset_password_instructions')}</Alert>
          <ForgotPasswordForm {...props} />
        </Card.Body>
      </Card>
    </div>
  );
};

const ForgotPasswordViewWrapper: FC<LoginViewProps> = (props) => <ForgotPasswordView {...props} />;

export default ForgotPasswordViewWrapper;
