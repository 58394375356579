import React, { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard';
import RegisterBanner from 'bundles/components/common/public/RegisterBanner';
import { publicImages } from 'bundles/utils';

import './styles.scss';

const AboutUsView = () => {
  const { t } = useTranslation();

  return (
    <>
      <Billboard>
        <Row className="justify-content-md-between">
          <Col lg={5}>
            <Billboard.Title
              title={t('public.about_us.billboard.title')}
              subTitle={<>{t('public.about_us.billboard.sub_title.0')}<span className="d-block mt-4" />{t('public.about_us.billboard.sub_title.1')}</>}
              btnLabel={t('public.labels.get_started')}
            />
          </Col>
          <Col lg={6}>
            <img className="billboard-image" alt="about us" src={publicImages.officeStuff} />
          </Col>
        </Row>
      </Billboard>
      <Container>
        <section>
          <Row>
            <Col sm={12}>
              <h2>{t('public.about_us.our_values.title')}</h2>
            </Col>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for passionate" src={publicImages.aim} />
              <h3 className="mt-2 mb-1">{t('public.about_us.our_values.passionate.title')}</h3>
              {t('public.about_us.our_values.passionate.content')}
            </Col>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for innovative" src={publicImages.bulp} />
              <h3>{t('public.about_us.our_values.innovative.title')}</h3>
              {t('public.about_us.our_values.innovative.content')}
            </Col>
            <Col lg={6} xl={4}>
              <img className="feature-icon" alt="icon for committed" src={publicImages.pick} />
              <h3 className="mt-2 mb-1">{t('public.about_us.our_values.committed.title')}</h3>
              {t('public.about_us.our_values.committed.content')}
            </Col>
          </Row>
        </section>

        <section>
          <h2 className="mt-7">{t('public.about_us.licenses_and_sertificates.title')}</h2>
          {t('public.about_us.licenses_and_sertificates.sub_title')}
          <h3 className="mt-7">{t('public.about_us.licenses_and_sertificates.fca_license.title')}</h3>
          {t('public.about_us.licenses_and_sertificates.fca_license.content')}
          <h3 className="mt-7">{t('public.about_us.licenses_and_sertificates.iso.title')}</h3>
          {t('public.about_us.licenses_and_sertificates.iso.content')}
          <h3 className="mt-7">{t('public.about_us.licenses_and_sertificates.iso_iec.title')}</h3>
          {t('public.about_us.licenses_and_sertificates.iso_iec.content')}
          <h3 className="mt-7">{t('public.about_us.licenses_and_sertificates.pci_dss.title')}</h3>
          {t('public.about_us.licenses_and_sertificates.pci_dss.content')}
        </section>

        <section>
          <Row className="my-10 justify-content-md-between">
            <Col xl={5} className="order-sm-2 order-1">
              <iframe className="map w-100" frameBorder={0} height={320} id="gmap_canvas" marginHeight={0} marginWidth={0} scrolling="no" src="https://maps.google.com/maps?q=Essell%2C%2029%20Howard%20Street&t=&z=13&ie=UTF8&iwloc=&output=embed" />
            </Col>
            <Col xl={5} className="order-sm-1 order-2">
              <h2>{t('public.about_us.registered_office.title')}</h2>
              <address>
                <p dangerouslySetInnerHTML={{ __html: t('public.about_us.registered_office.address') || '' }} />
              </address>
            </Col>
          </Row>
        </section>

        <RegisterBanner subTitle={t('public.titles.banner.better_way_to_pay.sub_title.about_us')} />
      </Container>
    </>
  );
};

const AboutUsViewWrapper: FC = () => <AboutUsView />;

export default AboutUsViewWrapper;
