import React, { FC } from 'react';
import { Container, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard/index';

const DocumentationHistoryView = ({ categories }:{categories: Record<string,any>[]}) => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="p-0">
          <Billboard title={t('public.documentation_history.title')} className="billboard-with-content-padding-bottom" />
        </Col>

        <Col lg={6} className="mx-auto mt-10">
          {categories.map(item=>
            <div id={item.id} key={item.id}>
              <h3>{item.category}</h3>
              <Table className="bg-white mb-7 table-striped border border-light-gray">
                <tbody>
                  {item.documents.map((document: any, i: any)=>
                    <tr key={i}>
                      <td className="align-middle p-4">{t('public.documentation_history.version')} {document.version}</td>
                      <td className="align-middle text-right p-4">
                        <a className="btn btn-primary" href={document.link}>{t('labels.download')}</a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const DocumentationHistoryViewWrapper: FC<{categories: Record<string,any>[]}> = (props: {categories: Record<string,any>[]}) => <DocumentationHistoryView {...props} />;

export default DocumentationHistoryViewWrapper;
