import { i18n } from 'bundles/utils';

export const setBtnLabel = (subTitle: string) => {
  let tln = '';

  switch(subTitle) {
  case i18n.t('public.titles.banner.better_way_to_pay.sub_title.about_us'):
    tln = 'public.labels.business';
    break;
  case i18n.t('public.titles.banner.better_way_to_pay.sub_title.home'):
    tln = 'public.labels.open_business_account';
    break;
  default:
    tln = 'public.labels.get_started';
  }

  return i18n.t(tln);
};
