import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Col, Row, Navbar } from 'react-bootstrap';

import { URL } from 'bundles/constants';
import { publicImages } from 'bundles/utils';
import { ErrorPageContentType } from './types';
import Billboard from 'bundles/components/common/public/Billboard';

import './styles.scss';
import { setTheme } from 'bundles/components/common/public/Header/utils';

const ErrorPageContent = ({ title, content }: ErrorPageContentType) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <header>
        <Navbar bg={setTheme} expand="lg" variant={setTheme === 'dark' ? setTheme : 'light'} fixed="top" className="navbar-top">
          <Container>
            <Navbar.Brand href={`/${i18n.language}${URL.HOME_PAGE}`}>
              <img alt="ezeeWallet" src={setTheme === 'dark' ? publicImages.logoWhite : publicImages.logo} />
            </Navbar.Brand>
          </Container>
        </Navbar>
      </header>

      <Container fluid>
        <Row>
          <Col sm={12} className="p-0">
            <Billboard title={title} className="billboard-with-content-padding-bottom" />
          </Col>
        </Row>
      </Container>
      <div className="text-center mt-9 px-3">
        <p dangerouslySetInnerHTML={{ __html: content }} />
        <a className="btn btn-primary mt-5" href={`/${i18n.language}${URL.HOME_PAGE}`}>{t('public.links.return_to_homepage')}</a>
      </div>

      <footer className="text-center py-5 px-3">
        <div className="copyright text-gray mb-5">{t('public.footer.copyright_content')}</div>
        <a href={URL.ZENDESK} target="_blank" rel="noreferrer">{t('public.labels.support')}</a>
      </footer>
    </>
  );
};

const ErrorPageContentWrapper: FC<ErrorPageContentType> = (props: ErrorPageContentType) => <ErrorPageContent {...props} />;

export default ErrorPageContentWrapper;
