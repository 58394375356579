import React from 'react';
import { Card } from 'react-bootstrap';
import { publicImages } from 'bundles/utils';

import { i18n } from 'bundles/utils';

const MerchantRegistrationThirdStep = () =>
  <Card>
    <Card.Body>
      <div className="under-review-message text-center">
        <img alt="success" src={publicImages.success} />
        <h1 className="h2 mb-4">{i18n.t('titles.registration.under_review')}</h1>
        <Card.Text className="mb-5">{i18n.t('public.messages.success.congratulations')}</Card.Text>
        <a className="btn btn-primary" href={`/${i18n.language}`}>{i18n.t('public.labels.return_to_home')}</a>
      </div>
    </Card.Body>
  </Card>;

export default MerchantRegistrationThirdStep;
