import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from 'bundles/components/common/ErrorPage';

const InternalErrorView = () => {
  const { t } = useTranslation();

  return <ErrorPage title={t('error_pages.500.title')} content={t('error_pages.500.content')} />;
};

const InternalErrorViewWrapper: FC = () => <InternalErrorView />;

export default InternalErrorViewWrapper;
