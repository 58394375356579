import { Cookies } from 'react-cookie-consent';

export const injectAnalyticsScript = (trackingId: string) => {

  // eslint-disable-next-line
  const gtag: Gtag.Gtag = function () {window.dataLayer.push(arguments as any);};

  if (!document.getElementById('ga-script')) {
    const script = document.createElement('script');
    script.id = 'ga-script';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;

    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];
    gtag('js', new Date());
    gtag('config', trackingId);
  }
};

export const removeAnalyticsScript = () => {
  const cookies = document.cookie.split(';');
  cookies.forEach(cookie => {
    const cookieName = cookie.trim().split('=')[0];
    if (cookieName.startsWith('_ga')) {
      Cookies.remove(cookieName);
    }
  });

};
