import React from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Introduction = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.introduction.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.introduction.what_is_ezeewallet.title')}</h3>
          <p>{t('public.faq.introduction.what_is_ezeewallet.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.introduction.why_choose_ezeewallet.title')}</h3>
          <p>{t('public.faq.introduction.why_choose_ezeewallet.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.introduction.is_my_ezeewallet_account_bank_account.title')}</h3>
          <p>{t('public.faq.introduction.is_my_ezeewallet_account_bank_account.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

export default Introduction;
