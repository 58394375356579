import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from 'bundles/components/common/ErrorPage';

const PageNotFoundView = () => {
  const { t } = useTranslation();

  return <ErrorPage title={t('error_pages.404.title')} content={t('error_pages.404.content')} />;
};

const PageNotFoundViewWrapper: FC = () => <PageNotFoundView />;

export default PageNotFoundViewWrapper;
