import React, { FC } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

import { URL } from 'bundles/constants';
import If from 'bundles/components/common/If';
import { setBtnLabel } from './utils';

import './styles.scss';

const RegisterBanner: FC<{title?: string; subTitle: string; className?: string}> = ({ title, subTitle, className }: {title?: string; subTitle: string; className?: string}) => {
  const { t, i18n } = useTranslation();

  return (
    <div className={`better-way-to-pay bg-secondary d-flex overflow-hidden justify-content-center flex-column align-items-center text-white position-relative ${className}`.trim()}>
      <h1>{title || t('public.titles.banner.better_way_to_pay.title')}</h1>
      <p className="my-5" dangerouslySetInnerHTML={{ __html: subTitle }} />
      <div className="position-relative actions">
        <If condition={subTitle === t('public.titles.banner.better_way_to_pay.sub_title.about_us')}>
          <a className="btn btn-primary mr-3 mr-sm-5" href={`/${i18n.language}${URL.CONSUMERS_REGISTRATIONS_NEW}`}>
            {t(`${subTitle === t('public.titles.banner.better_way_to_pay.sub_title.about_us') ? 'public.labels.personal': 'public.labels.get_started'}`)}
            <FontAwesomeIcon className="ml-3" size="lg" icon={faArrowRightLong} />
          </a>
        </If>

        <a className="btn btn-primary" href={`/${i18n.language}${URL.MERCHANTS_REGISTRATIONS_NEW}`}>
          {setBtnLabel(subTitle)}
          <FontAwesomeIcon className="ml-3" size="lg" icon={faArrowRightLong} />
        </a>
      </div>
    </div>
  );
};

export default RegisterBanner;
