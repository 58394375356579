import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Introduction: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.registration.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.registration.how_do_i_sign_up.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: t('public.faq.registration.how_do_i_sign_up.content') }} />
        </li>
        <li>
          <h3>{t('public.faq.registration.who_can_open_account.title')}</h3>
          <p>{t('public.faq.registration.who_can_open_account.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.registration.what_do_need_to_open_account.title')}</h3>
          <p>{t('public.faq.registration.what_do_need_to_open_account.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.registration.can_register_more_accounts.title')}</h3>
          <p>{t('public.faq.registration.can_register_more_accounts.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const IntroductionWrapper: FC<{eventKey: string}> = props => <Introduction {...props} />;

export default IntroductionWrapper;
