import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';

import VerifyPhoneNumberForm from 'bundles/components/common/VerifyPhoneNumber/VerifyPhoneNumberForm';
import { IVerifyPhoneNumberView } from 'bundles/components/common/VerifyPhoneNumber/types';

import If from 'bundles/components/common/If';

const VerifyPhoneNumberView = (props: IVerifyPhoneNumberView) => {
  const { t } = useTranslation();

  return (
    <div className="sign-in">
      <If condition={props.inline_message}>
        <div className="d-flex justify-content-center" dangerouslySetInnerHTML={{ __html: props.inline_message || '' }} />
      </If>

      <Card>
        <Card.Header as={'h1'} className="border-0">{t('labels.verify_your_number')}</Card.Header>
        <Card.Body>
          <VerifyPhoneNumberForm {...props} />
        </Card.Body>
      </Card>
    </div>
  );
};

const VerifyPhoneNumberViewWrapper: FC<IVerifyPhoneNumberView> = (props) => <VerifyPhoneNumberView {...props} />;

export default VerifyPhoneNumberViewWrapper;
