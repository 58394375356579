import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Form, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';

import { InputFormGroup } from 'bundles/components/common/Form';
import { isRequired, isValidEmail, isUserRegistered } from 'bundles/components/common/Form/validations';
import If from 'bundles/components/common/If';
import { URL } from 'bundles/constants';
import { Step } from 'bundles/components/common/Steps';
import Loader from 'bundles/components/common/Loader';
import { useMerchantRegistrationFirstStep } from 'bundles/hooks';
import Recaptcha from 'bundles/components/common/Recaptcha';

const MerchantRegistrationFormFirstStep = () => {
  const { t } = useTranslation();
  const { register, errors, state, setValue, handleSubmitSignUp, isSubmitted } = useMerchantRegistrationFirstStep();

  return (
    <Card>
      <Card.Header as={'h1'} className="border-0">{t('public.titles.business_account')}</Card.Header>

      <Card.Body>
        <Form className="js-no-disable-submit-form">
          <div className="form-section-title mt-0">{t('public.labels.personal')}</div>

          <Row>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][first_name]"
                label={t('labels.name')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={50}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][last_name]"
                label={t('labels.last_name')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={50}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][email]"
                label={t('labels.email')}
                register={register}
                validate={{ isRequired, isValidEmail, isUserRegistered: value => isUserRegistered(value, state.errorFields?.email?.value, state.errorFields?.email?.message) }}
                errors={errors}
                maxLength={250}
                autoComplete="off"
              />
            </Col>
            <Col xl={6}>
              <InputFormGroup
                name="merchant_registration[authorized_business_representative_attributes][position]"
                label={t('labels.position')}
                register={register}
                validate={{ isRequired }}
                errors={errors}
                maxLength={250}
                autoComplete="off"
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Group>
                <If condition={state.recaptcha_key}>
                  <Recaptcha sitekey={state.recaptcha_key} register={register} setValue={setValue} refreshReCaptcha={state.refreshReCaptcha} action="merchant_registration" />
                </If>
              </Form.Group>
            </Col>
          </Row>

          <input type="hidden" {...register('step_number')} />

          <Step.Next onClickNext={handleSubmitSignUp} className="float-right btn-next">
            <Loader url={isSubmitted ? URL.MERCHANTS_REGISTRATIONS: ''} className={'text-center loading--over loading--with-fog'} />
            {t('labels.continue')}
            <FontAwesomeIcon icon={faArrowRightLong} className="ml-4" size="lg" />
          </Step.Next>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default MerchantRegistrationFormFirstStep;
