import React, { FC } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard/index';
import { URL } from 'bundles/constants';

const TermsOfUseView = () => {
  const { t, i18n } = useTranslation();

  return (
    <Container fluid className="legal-documents">
      <Row>
        <Col sm={12} className="p-0">
          <Billboard title={t('labels.terms_of_use')} className="billboard-with-content-padding-bottom" />
        </Col>

        <Col lg={8} className="mx-auto mt-10 mb-5">
          <p>These Terms of Use are intended for those accessing or using the website at <a href={`/${i18n.language}`}>www.ezeewallet.com</a>, including all contents on it (the &quot;website&quot;). This website is owned and operated by eZeeWallet (&quot;we&quot;, &quot;us&quot;, &quot;our&quot;), the DBA (doing business as) name of emerchantpay ltd., a company organised and existing under the laws of England and Wales and having its registered office at Essell, 29 Howard Street, North Shields, Tyne and Wear, NE30 1AR, United Kingdom, registered with Companies House under number 05153270.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Accessing or using our website</h2>
          <p>Our website is made available free of charge. We do not guarantee that our website, or any content on it, will always be available uninterrupted. We may suspend, withdraw, discontinue or change all or any part of it without prior notice. We will not be liable to you (&quot;you&quot;, &quot;your&quot;) if for any reason our website is unavailable at any time or for any period.</p>
          <p>By accessing our website, you consent and agree to comply with the Terms of Use as set out below.</p>
          <p className="bold">IF YOU DO NOT AGREE TO THESE TERMS OF USE, PLEASE CEASE USING OUR WEBSITE IMMEDIATELY AND REFRAIN FROM ACCESS IN THE FUTURE.</p>
          <p className="mb-2">These Terms of Use refer to the following additional terms as they also apply to your use of our website:</p>
          <ul className="reset-list-style">
            <li>our <a href={`/${i18n.language}${URL.PRIVACY_POLICY}`} className="mr-3" target="_blank" rel="noreferrer">{t('labels.privacy_policy')}</a> which sets out our purposes for collection and processing of personal data and describes your rights in this connection;</li>
            <li>our <a href={`/${i18n.language}${URL.COOKIE_POLICY}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.cookie_policy')}</a> which describes the cookies we use on our website;</li>
            <li>our <a href={`/${i18n.language}${URL.COMPLAINTS_HANDLING_POLICY}`} className="mr-3" target="_blank" rel="noreferrer">{t('public.labels.complaints_handling_policy')}</a> which provides you with the tools to make a complaint regarding our organisation, our staff members or the quality of our services.</li>
          </ul>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Changes to these Terms of Use</h2>
          <p>We may revise these Terms of Use at our sole discretion with immediate effect by amending this page. We encourage you to periodically review these terms as changes may be adopted from time to time. These Terms of Use were last updated on July12 ,2018.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Allowed use of the website and contents</h2>
          <p>You hereby expressly acknowledge and agree that we are the owner or the licensee of all intellectual property rights on our website, whereas all such materials are copyright protected. The website and all its contents and functions are made available to you &quot;as is&quot; and &quot;as available&quot; for your information and personal use only. Subject to your acceptance of and compliance with these Terms of Use, we grant you a personal, non-commercial, revocable, non-exclusive, non-assignable right to use this website and its contents.</p>
          <p>You agree that you will not use, copy, modify, adapt, distribute, transmit, display or otherwise exploit our website or its contents for any other purpose whatsoever, unless with our prior written consent. You further agree that you will not use any illustrations, photographs, video sequences or any graphics separately from any accompanying text. You also agree that you will not remove, obscure or alter any copyright notice, trademark or other proprietary rights notices affixed to or contained within this website or its contents. You are permitted to download a copy or extracts of any page(s) from our website for your personal use only and you may draw the attention of others to the contents of our website. Please make sure that the latter are made aware of these Terms of Use.</p>
          <p>Any misuse of this website and its contents contradictory to these Terms of Use or applicable law is strictly prohibited and represents a violation of this agreement and intellectual property rights. Such misuse may therefore lead to termination of your right to access or use this website and its contents, as well to legal action.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Information on this website</h2>
          <p>You hereby acknowledge and agree that the content on our website is provided for general information only. Although we make reasonable efforts to update the content regularly, we make no representations, warranties or guarantees, whether express or implied, that the latter is comprehensive, accurate and up to date. You should not rely on such information for business or legal decisions.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Information on this website</h2>
          <p>We do not guarantee that our website will be secure or free from bugs and viruses. You are responsible for configuring your information technology, computer programmes and platform in order to access our website. You should use your own protection software.</p>
          <p>You must not misuse the website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful. You must not attempt to gain unauthorised access to this website, the servers on which this website is stored, or any server, computer or database connected to this website. You must not attack our website via a denial-of-service attack or a distributed denial-of-service attack.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Links to third-party websites</h2>
          <p>Where our website contains links to third-party websites or resources provided by third parties, you acknowledge that your access to or use of these websites and resources is entirely at your own discretion and at your own risk and that you will be solely responsible for any loss or damage that may result from such access or use.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Disclaimer</h2>
          <p>The website and any contents on it are made available to you on an &quot;as is&quot; and &quot;as available&quot; basis with no warranties whatsoever. We and our affiliates, partners, licensors, suppliers and the respective directors, employees, agents and shareholders (collectively referred to as the &quot;eZeeWallet Parties&quot;) do not assume any liability and disclaim any responsibility for any consequences resulting from your access or use of this website and its contents.</p>
          <p>The eZeeWallet Parties specifically and explicitly disclaim to the fullest extent permitted by law any and all representations, guarantees and warranties, whether express or implied, with respect to this website and its contents, including, without limitation, warranties to the accuracy, performance, merchantability, fitness for a particular purpose and non-infringement. The eZeeWallet Parties also disclaim any warranties and liability regarding the accuracy, completeness, security, reliability, timeliness and performance of this website and its contents.</p>
          <p>Some countries and jurisdictions do now allow the exclusion or disclaimer of certain terms or warranties, so the above exclusions in whole or in part may not apply fully to you. In such event, these exclusions shall apply to you to the extent permitted by applicable law. You may also have other rights that vary from country to country and jurisdiction to jurisdiction.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">Limitation of liability</h2>
          <p>Nothing in these Terms of Use shall attempt to exclude or limit our liability to an extent that is not permissible under the applicable law, including, without limitation, for death or personal injury arising from negligence or our fraudulent misrepresentation.</p>
          <p>To the extent permitted by law, we exclude all conditions, warranties, representations or other terms which may apply to our website or any contents on it, whether express or implied.</p>
          <p className="mb-2">We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</p>
          <ul className="reset-list-style">
            <li>use of or inability to use our website; or</li>
            <li>use of or reliance on any content displayed on our website.</li>
          </ul>
          <p>Please note that, we will not be liable to you for loss of any profit, sales or revenue; loss of business opportunity, goodwill or reputation; business interruption or any indirect or consequential loss or damage.</p>
          <p>We assume no liability for the content of websites linked on our website. Such links should not be interpreted as endorsement by us of those linked websites. We will not be liable for any loss or damage that may arise from your use of them.</p>
          <p>We will not be liable for any loss or damage caused by a virus, distributed denial-of-service attack, or other technologically harmful material that may infect your computer equipment, computer programmes, data or other proprietary material due to your use of the website or to your downloading of any content on it or on any website linked to it.</p>
          <p>Some countries and jurisdictions do not allow limitations of liability of certain types of damages. In the event that certain limitations are unenforceable in your country or jurisdiction, the aggregate liability of the eZeeWallet Parties shall under no circumstances whatsoever exceed GBP100.00.</p>
        </Col>

        <Col lg={8} className="mx-auto">
          <h2 className="h3">Miscellaneous provisions</h2>
          <ul className="reset-list-style">
            <li>
              <h5>Entire agreement</h5>
              <p className="mb-0">These Terms of Use and any additional documents or policies constitute the entire agreement between you and us and supersede and replace all prior understandings or agreements regarding the subject matter hereof. Any waiver of any provision of these Terms of Use will be effective only if in writing and signed by us.</p>
            </li>
            <li>
              <h5>No waiver</h5>
              <p className="mb-0">These Terms of Use and any additional documents or policies constitute the entire agreement between you and us and supersede and replace all prior understandings or agreements regarding the subject matter hereof. Any waiver of any provision of these Terms of Use will be effective only if in writing and signed by us.</p>
            </li>
            <li>
              <h5>Severability</h5>
              <p className="mb-0">All provisions of these Terms of Use are distinct and severable. If any provision of these Terms of Use is or becomes invalid or unenforceable, the provision will be treated as if it was not in the agreement and the remaining provisions will still be valid and enforceable.</p>
            </li>
            <li>
              <h5>Governing law and dispute resolution</h5>
              <p>Although our website can be accessed from numerous places and countries around the world, that shall not be construed as consent to abide by that local jurisdiction. Any relationship between you and us arising out of these Terms of Use shall be governed by the laws of England and Wales.</p>
              <p className="mb-0">All disputes, arising out of these Terms of Use or related to them, including those arising out of or concerning its interpretation, invalidity, performance or termination, shall be referred for resolution to the competent courts of England and Wales.</p>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

const TermsOfUseViewWrapper: FC = () => <TermsOfUseView />;

export default TermsOfUseViewWrapper;
