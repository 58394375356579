import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import ErrorPage from 'bundles/components/common/ErrorPage';

const ForbiddenPageView = () => {
  const { t } = useTranslation();

  return <ErrorPage title={t('error_pages.403.title')} content={t('error_pages.403.content')} />;
};

const ForbiddenPageViewWrapper: FC = () => <ForbiddenPageView />;

export default ForbiddenPageViewWrapper;
