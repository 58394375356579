import React, { SyntheticEvent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import { isValidEmail, isRequired } from 'bundles/components/common/Form/validations';
import { InputFormGroup } from 'bundles/components/common/Form';
import Recaptcha from 'bundles/components/common/Recaptcha';
import { ForgotPasswordFormValues, ForgotPasswordFormProps } from './types';
import { URL } from 'bundles/constants';

const ForgotPasswordForm = ({ email, recaptcha_key }: ForgotPasswordFormProps) => {
  const { t, i18n } = useTranslation();
  const { register, handleSubmit, formState: { errors, isSubmitting }, setValue } = useForm();

  const onSubmitForm = async (fd: ForgotPasswordFormValues, e: SyntheticEvent) => (e.target as HTMLFormElement).submit();

  return (
    <Form onSubmit={handleSubmit(onSubmitForm)} action={`/${i18n.language}${URL.RESET_PASSWORD}`} method="post" noValidate>
      <InputFormGroup
        type="email"
        name="email"
        label={t('labels.email_address')}
        register={register}
        defaultValue={email}
        validate={{ isRequired, isValidEmail }}
        errors={errors}
      />

      <Recaptcha sitekey={recaptcha_key} register={register} setValue={setValue} action="reset_password" />

      <input value={t('labels.submit')} className="btn btn-primary btn-block" disabled={isSubmitting} type="submit" />
    </Form>
  );
};

export default ForgotPasswordForm;
