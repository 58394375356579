import React, { FC } from 'react';
import { Container, Table, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCrown } from '@fortawesome/pro-solid-svg-icons';

import { faCheck, faAsterisk } from '@fortawesome/pro-light-svg-icons';
import Billboard from 'bundles/components/common/public/Billboard';
import RegisterBanner from 'bundles/components/common/public/RegisterBanner';
import { URL } from 'bundles/constants';
import { publicImages } from 'bundles/utils';
import If from 'bundles/components/common/If';

import './styles.scss';

const VipView = ({ inline_message, is_user_signed_in } : {inline_message: string; is_user_signed_in: string}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Billboard className="billboard-vip text-secondary bg-lighter-gray">
        <Row>
          <If condition={inline_message}>
            <Col sm={12}>
              <span className="mb-5 d-inline-block" dangerouslySetInnerHTML={{ __html: inline_message }} />
            </Col>
          </If>
          <Col lg={5}>
            <Billboard.Title
              title={t('public.labels.the_vip_club')}
              subTitle={t('public.vip.billboard.sub_title')}
              btnLabel={t('public.labels.get_started')}
              btnUrl={`/${i18n.language}${URL.CONSUMERS_NEW}`}
            />
          </Col>
          <Col lg={7} className="position-relative">
            <img className="billboard-image" alt="eZeeWallet VIP club dashboard" src={publicImages.dashboardScreenVip} />
          </Col>
        </Row>
      </Billboard>

      <Container>
        <section>
          <h2>{t('public.vip.exclusive_benefits.title')}</h2>
          <Row>
            <Col lg={6} xl={3} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for lower fees" src={publicImages.chart} />
              <h3 className="mt-2 mb-1">{t('public.vip.exclusive_benefits.lower_fees.title')}</h3>
              {t('public.vip.exclusive_benefits.lower_fees.content')}
            </Col>
            <Col lg={6} xl={3} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for higher limits" src={publicImages.dashboard} />
              <h3 className="mt-2 mb-1">{t('public.vip.exclusive_benefits.higher_limits.title')}</h3>
              {t('public.vip.exclusive_benefits.higher_limits.content')}
            </Col>
            <Col lg={6} xl={3} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for exclusive promotions" src={publicImages.label} />
              <h3 className="mt-2 mb-1">{t('public.vip.exclusive_benefits.exclusive_promotions.title')}</h3>
              {t('public.vip.exclusive_benefits.exclusive_promotions.content')}
            </Col>
            <Col lg={6} xl={3}>
              <img className="feature-icon" alt="icon for VIP support" src={publicImages.crown} />
              <h3 className="mt-2 mb-1">{t('public.vip.exclusive_benefits.vip_support.title')}</h3>
              {t('public.vip.exclusive_benefits.vip_support.content')}
            </Col>
          </Row>
        </section>
      </Container>

      <Container fluid className="billboard-aside billboard-aside-right bg-secondary billboard-aside-loyalty-points">
        <Container>
          <section>
            <Row className="justify-content-md-between">
              <Col xl={4}>
                <h2>{t('public.vip.loyalty_points.title')}</h2>
                <p>{t('public.vip.loyalty_points.join_vip_club')}</p>
                <p>{t('public.vip.loyalty_points.spend_money_for_loyalty_points')}</p>
              </Col>
              <Col xl={7} className="pt-5 pb-6 text-md-center">
                <h3 className="text-white mb-5">{t('public.vip.loyalty_points.for_each_euro_spent')}</h3>

                <div className="w-lg-75">
                  <div className="d-xl-inline-block position-relative">
                    <div className="bg-white p-4 table-loyalty-points-wrapper">
                      <Table className="w-100 text-left" borderless>
                        <thead>
                          <tr>
                            <th>{t('public.vip.loyalty_points.title')}</th>
                            <th>{t('public.vip.loyalty_points.cashback')}</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr><td>50,000</td><td>€50</td></tr>
                          <tr><td>100,000</td><td>€80</td></tr>
                          <tr><td>150,000</td><td>€100</td></tr>
                          <tr><td>250,000</td><td>€150</td></tr>
                          <tr><td>400,000</td><td>€300</td></tr>
                          <tr><td>600,000</td><td>€500</td></tr>
                          <tr><td>1,000,000</td><td>€800</td></tr>
                        </tbody>
                      </Table>
                    </div>

                    <div className="bg-white mt-7 mt-xl-auto table-silver-vip-level-wrapper py-3 px-5">
                      <Table className="w-100 m-0 no-wrap" borderless>
                        <thead>
                          <tr>
                            <th><FontAwesomeIcon icon={faCrown} className="vip-bronze position-absolute" /></th>
                            <th><FontAwesomeIcon icon={faCrown} className="vip-silver position-absolute" /></th>
                            <th><FontAwesomeIcon icon={faCrown} className="vip-gold position-absolute" /></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td><span>{t('titles.vip.bronze')}</span>1.00 pt</td>
                            <td><span>{t('titles.vip.silver')}</span>1.25 pt</td>
                            <td><span>{t('titles.vip.gold')}</span>1.50 pt</td>
                          </tr>
                          <tr>
                            <td className="text-left pl-0 pb-0">{t('public.vip.loyalty_points.you_spend')}<p className="text-primary m-0">€10 000</p></td>
                            <td />
                            <td className="text-right pr-0 pb-0">{t('public.vip.loyalty_points.you_get')}<p className="text-primary m-0">12 500 pt</p></td>
                          </tr>
                        </tbody>
                      </Table>
                      <p className="position-absolute text-white">{t('public.vip.loyalty_points.higher_level')}</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </Container>

      <Container>
        <section className="join-the-club">
          <Row className="justify-content-md-between">
            <Col xl={6} className="position-relative order-xl-1 order-2">
              <div className="position-relative">
                <img alt="dots" src={publicImages.dots} className="position-absolute image-dots" />
                <img alt="eZeeWallet diversity" src={publicImages.golf} className="position-relative image-golf" />
              </div>
              <div className="bg-white table-join-the-club-wrapper p-5 position-absolute">
                <div className="d-flex justify-content-between table-join-the-club-title">
                  <span className="mr-6">{t('public.vip.how_to_join_the_club.progress')}</span> Q3: 01 Jul -31 Sep
                </div>
                <Table className="mt-5 mb-0 text-center w-100" borderless>
                  <tbody>
                    <tr>
                      <td className="table-join-the-club-vip-amount text-left">€15K</td>
                      <td className="table-join-the-club-vip-amount">€30K</td>
                      <td className="table-join-the-club-vip-amount text-right">€90K</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className="progress-bar">
                          <span className="progress-bar-filled position-relative">
                            <i className="tooltip bg-secondary">23,570</i>
                          </span>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-left"><FontAwesomeIcon icon={faCrown} className="vip-bronze" /></td>
                      <td><FontAwesomeIcon icon={faCrown} className="vip-silver" /></td>
                      <td className="text-right"><FontAwesomeIcon icon={faCrown} className="vip-gold" /></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xl={5} className="order-xl-2 order-1">
              <h2>{t('public.vip.how_to_join_the_club.title')}</h2>
              <p>{t('public.vip.how_to_join_the_club.access_full_experience')}</p>
              <p>{t('public.vip.how_to_join_the_club.reached_minimum_amount')}</p>
              <p>{t('public.vip.how_to_join_the_club.level_up_status')}</p>
            </Col>
          </Row>
        </section>
        <section className="vip-benefits">
          <Row>
            <Col xl={12}>
              <h2>{t('public.vip.your_benefits.title')}</h2>
            </Col>
            <Col xl={12}>
              <div className="d-flex justify-content-around px-lg-10 vip-benefits-items">
                <div className="vip-benefits-item bronze px-5 pt-5 pb-6 position-relative">
                  <FontAwesomeIcon icon={faCrown} className="vip-bronze" />
                  <h3 className="vip-bronze mt-4 mb-4">{t('titles.vip.bronze')}</h3>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    <strong>{t('titles.vip.benefits.bronze.0')}</strong>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    <span>{t('titles.vip.benefits.bronze.1')}</span>
                  </p>
                  <p className="position-absolute explanation">
                    <FontAwesomeIcon icon={faAsterisk} className="vip-gold mr-2" />
                    <span
                      dangerouslySetInnerHTML={{ __html: t('titles.vip.benefits.quarter_transactions', { amount: '15,000', currency: 'EUR' }) }}
                    />
                  </p>
                </div>

                <div className="vip-benefits-item silver px-5 pt-5 pb-6 position-relative">
                  <FontAwesomeIcon icon={faCrown} className="vip-silver" />
                  <h3 className="vip-silver mt-4 mb-4">{t('titles.vip.silver')}</h3>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    <strong>{t('titles.vip.benefits.silver.0')}</strong>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.silver.1')}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.silver.2')}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.silver.3')}
                  </p>
                  <p className="position-absolute explanation">
                    <FontAwesomeIcon icon={faAsterisk} className="vip-gold mr-2" />
                    <span
                      dangerouslySetInnerHTML={{ __html: t('titles.vip.benefits.quarter_transactions', { amount: '30,000', currency: 'EUR' }) }}
                    />
                  </p>
                </div>

                <div className="vip-benefits-item gold px-5 pt-5 pb-6 position-relative">
                  <img alt="couple" src={publicImages.couple} className="d-none d-xl-block" />
                  <FontAwesomeIcon icon={faCrown} className="vip-gold" />
                  <h3 className="vip-gold mt-4 mb-4">{t('titles.vip.gold')}</h3>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    <strong>{t('titles.vip.benefits.gold.0')}</strong>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.gold.1')}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.gold.2')}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.gold.3')}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.gold.4')}
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {t('titles.vip.benefits.gold.5')}
                  </p>
                  <p className="position-absolute explanation">
                    <FontAwesomeIcon icon={faAsterisk} className="vip-gold mr-2" />
                    <span
                      dangerouslySetInnerHTML={{ __html: t('titles.vip.benefits.quarter_transactions', { amount: '90,000', currency: 'EUR' }) }}
                    />
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </section>

        <If condition={!is_user_signed_in}>
          <section className="mx-auto">
            <RegisterBanner title={t('public.titles.banner.what_are_you_waiting.title')} subTitle={t('public.titles.banner.what_are_you_waiting.sub_title')} className="bg-lighter-gray text-secondary" />
          </section>
        </If>
      </Container>
    </>
  );
};

const VipViewWrapper: FC<{inline_message: string; is_user_signed_in: string}> = props => <VipView {...props} />;

export default VipViewWrapper;
