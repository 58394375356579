import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const OtherQuestions: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.other_questions.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.other_questions.file_complaint.title')}</h3>
          <p>{t('public.faq.other_questions.file_complaint.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.other_questions.customer_support_languages.title')}</h3>
          <p>{t('public.faq.other_questions.customer_support_languages.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.other_questions.customer_support_available_24.title')}</h3>
          <p>{t('public.faq.other_questions.customer_support_available_24.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.other_questions.contact_customer_support.title')}</h3>
          <p>{t('public.faq.other_questions.contact_customer_support.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const OtherQuestionsWrapper: FC<{eventKey: string}> = props => <OtherQuestions {...props} />;

export default OtherQuestionsWrapper;
