import React, { FC } from 'react';

import Billboard from 'bundles/components/common/public/Billboard/Billboard';
import Title from 'bundles/components/common/public/Billboard/Title';
import { BillboardPropsType } from './Billboard/types';

const BillboardWrapper: FC<BillboardPropsType> & { Title: typeof Title }  = props => <Billboard {...props} />;

BillboardWrapper.Title = Title;

export default BillboardWrapper;
