import { SyntheticEvent } from 'react';
import { InputProcessingParams, PasteProcessingParams, TInputElsRef } from 'bundles/components/public/verifySignIn/types';

const onFillGoNext = (e: SyntheticEvent, inputEls: TInputElsRef) => {
  const nextInput = inputEls.current[parseInt((e.target as HTMLInputElement).dataset.inputNumber || '', 10) + 1];
  (nextInput && (e.target as HTMLInputElement).value) && nextInput.focus();
};

export const randomHash = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5);

export const onEmpty_FocusFirstEmptyInput = (e: SyntheticEvent, inputEls: TInputElsRef ) => {
  if ((e.target as HTMLInputElement).value) return;
  for (const key in inputEls.current) {
    if (Object.prototype.hasOwnProperty.call(inputEls.current, key) && !inputEls.current[key].value) {
      return inputEls.current[key].focus();
    }
  }
};

export const pasteProcessing: PasteProcessingParams = (e, inputEls, setClipboardData, setCurrentValue, setInvalidCode, clearErrors) => {
  const clipboardData = (e.clipboardData  as Record<string, any>);

  setClipboardData(clipboardData.getData('Text'));
  setCurrentValue((e.target as HTMLInputElement).value);
  clearErrors();
  if ((/^\d{6}$/.test(clipboardData.getData('Text')))) {
    for (const key in inputEls.current) {
      if (Object.prototype.hasOwnProperty.call(inputEls.current, key)) {
        inputEls.current[key].value = clipboardData.getData('Text').charAt(parseInt(key, 10));
      }
    }
    return setInvalidCode(false);
  }
  setInvalidCode(true);
};

export const inputProcessing: InputProcessingParams = (e, inputEls, clipboardData, setClipboardData, currentValue, setInvalidCode, clearErrors) => {
  const target = (e.target as HTMLInputElement);

  if(clipboardData && !/^\d{6}$/.test(clipboardData)) {
    target.value = currentValue;
    clearErrors();
    setInvalidCode(true);
    return setClipboardData('');
  }


  if(!clipboardData && target.value.length > 1) target.value = target.value[1];
  target.value = target.value[0];
  target.value = target.value.replace(/[^0-9]/g, '');

  if(!clipboardData) {
    onFillGoNext(e, inputEls);
    onEmpty_FocusFirstEmptyInput(e, inputEls);
  }

  setInvalidCode(false);
  setClipboardData('');
};
