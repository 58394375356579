import React, { FC } from 'react';
import { Col, Row, Accordion, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard/index';
import Introduction from 'bundles/components/public/faq/Introduction';
import Registration from 'bundles/components/public/faq/Registration';
import Fees from 'bundles/components/public/faq/Fees';
import Currencies from 'bundles/components/public/faq/Currencies';
import AccountRelated from 'bundles/components/public/faq/AccountRelated';
import Deposits from 'bundles/components/public/faq/Deposits';
import Withdrawals from 'bundles/components/public/faq/Withdrawals';
import TransactionsAndPayments from 'bundles/components/public/faq/TransactionsAndPayments';
import Security from 'bundles/components/public/faq/Security';
import OtherQuestions from 'bundles/components/public/faq/OtherQuestions';
import { SETTINGS } from 'bundles/constants/index';

const FaqView = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="p-0">
          <Billboard title={t('public.faq.title')} className="billboard-with-content-padding-bottom" />
        </Col>

        <Col lg={8} className="mx-auto mt-10 mb-8">
          <h2 className="h3">{t('public.faq.ezeewallet_support.title')}</h2>
          <p>{t('public.faq.ezeewallet_support.content')}</p>
        </Col>

        <Col lg={8} className="mx-auto">
          <h2 className="h3">{t('public.faq.categories')}</h2>
          <Accordion className="mb-8">
            <Introduction eventKey="1" />
            <Registration eventKey="2" />
            <Fees eventKey="3" />
            <Currencies eventKey="4" />
            <AccountRelated eventKey="5" />
            <Deposits eventKey="6" />
            <Withdrawals eventKey="7" />
            <TransactionsAndPayments eventKey="8" />
            <Security eventKey="9" />
            <OtherQuestions eventKey="10" />
          </Accordion>

          <Row>
            <Col md={6} className="mt-5">
              <h2 className="h3">{t('public.faq.email_support.title')}</h2>
              <p dangerouslySetInnerHTML={{ __html: t('public.faq.email_support.content', { email_address: SETTINGS.SUPPORT_EMAIL_ADDRESS }) }} />
            </Col>
            <Col md={6} className="mt-5">
              <h2 className="h3">{t('public.faq.phone_support.title')}</h2>
              <p dangerouslySetInnerHTML={{ __html: t('public.faq.phone_support.content', { email_address: SETTINGS.SUPPORT_EMAIL_ADDRESS }) }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const FaqViewWrapper: FC = () => <FaqView />;

export default FaqViewWrapper;
