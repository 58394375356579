import React, { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { URL } from 'bundles/constants';
import { publicImages } from 'bundles/utils';
import Billboard from 'bundles/components/common/public/Billboard';
import BillboardTitle from 'bundles/components/common/public/Billboard/Title';
import RegisterBanner from 'bundles/components/common/public/RegisterBanner';
import If from 'bundles/components/common/If';

import './styles.scss';

const BusinessView = ({ is_user_signed_in } : {is_user_signed_in: string}) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Billboard>
        <Row>
          <Col lg={5}>
            <BillboardTitle
              title={t('public.business.billboard.title')}
              subTitle={t('public.business.billboard.sub_title')}
              btnLabel={t('public.labels.get_started')}
              btnUrl={`/${i18n.language}${URL.MERCHANTS_REGISTRATIONS_NEW}`}
            />
          </Col>
          <Col lg={7}>
            <img className="billboard-image" alt="eZeeWallet dashboard" src={publicImages.dashboardScreen} />
          </Col>
        </Row>
      </Billboard>

      <Container>
        <section>
          <h2>{t('public.business.business_benefits.title')}</h2>
          <Row>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for secure" src={publicImages.lock} />
              <h3 className="mt-2 mb-1">{t('public.business.business_benefits.secure.title')}</h3>
              {t('public.business.business_benefits.secure.content')}
            </Col>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for simple" src={publicImages.checkWithDoubleCircle} />
              <h3>{t('public.business.business_benefits.simple.title')}</h3>
              {t('public.business.business_benefits.simple.content')}
            </Col>
            <Col lg={6} xl={4}>
              <img className="feature-icon" alt="icon for seamless" src={publicImages.arrows} />
              <h3 className="mt-2 mb-1">{t('public.business.business_benefits.seamless.title')}</h3>
              {t('public.business.business_benefits.seamless.content')}
            </Col>
          </Row>
        </section>
      </Container>

      <Container fluid className="billboard-aside billboard-aside-right billboard-aside-business bg-secondary">
        <Container>
          <section>
            <Row className="justify-content-md-between">
              <Col xl={5} className="pb-xl-10">
                <h2>{t('public.business.payment_portfolio.title')}</h2>
                <p>{t('public.business.payment_portfolio.global_digital_wallet')}</p>
                <p>{t('public.business.payment_portfolio.payment_methods_range')}</p>
                <p>{t('public.business.payment_portfolio.selected_payment_method')}</p>
              </Col>
              <Col xl={6} className="d-flex align-items-center">
                <div className="partners d-flex ml-md-10 flex-wrap justify-content-between">
                  <img alt="blik" src={publicImages.blik} />
                  <img alt="payid" src={publicImages.payid} />
                  <img alt="neosurf" src={publicImages.neosurf} />
                  <img alt="paysafecard" src={publicImages.paysafecard} />
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </Container>

      <If condition={!is_user_signed_in}>
        <Container>
          <section className="mx-auto">
            <RegisterBanner title={t('public.titles.banner.better_way_get_paid.title')} subTitle={t('public.titles.banner.better_way_get_paid.sub_title')} />
          </section>
        </Container>
      </If>
    </>
  );
};

const BusinessViewWrapper: FC<{is_user_signed_in: string}> = props => <BusinessView {...props} />;

export default BusinessViewWrapper;
