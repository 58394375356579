import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Withdrawals: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.withdrawals.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.withdrawals.where_can_withdraw_funds.title')}</h3>
          <p>{t('public.faq.withdrawals.where_can_withdraw_funds.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.withdrawals.withdraw_cost.title')}</h3>
          <p>{t('public.faq.withdrawals.withdraw_cost.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.withdrawals.process_withdrawal.title')}</h3>
          <p>{t('public.faq.withdrawals.process_withdrawal.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.withdrawals.unable_transfer_funds.title')}</h3>
          <p>{t('public.faq.withdrawals.unable_transfer_funds.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.withdrawals.withdrawals_in_different_currency.title')}</h3>
          <p>{t('public.faq.withdrawals.withdrawals_in_different_currency.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.withdrawals.withdrawals_in_different_currency.title')}</h3>
          <p>{t('public.faq.withdrawals.withdrawals_in_different_currency.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.withdrawals.withdrawal_appears_processed_not_received_funds.title')}</h3>
          <p>{t('public.faq.withdrawals.withdrawal_appears_processed_not_received_funds.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const WithdrawalsWrapper: FC<{eventKey: string}> = props => <Withdrawals {...props} />;

export default WithdrawalsWrapper;
