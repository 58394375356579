import React, { useState } from 'react';
import { Cookies } from 'react-cookie-consent';
import { Button, Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { URL } from 'bundles/constants';
import { ANALYTICS } from 'bundles/constants/settings';
import Billboard from 'bundles/components/common/public/Billboard/index';
import { injectAnalyticsScript, removeAnalyticsScript } from 'bundles/views/public/Cookies/util';

const CookieSettingsView = ({ googleAnalyticsId }: { googleAnalyticsId: string }) => {
  const { t, i18n } = useTranslation();
  const [analyticsEnabled, setAnalyticsEnabled] = useState(Cookies.get(ANALYTICS) === 'enabled');

  const handleSaveSettings = () => {
    if (!analyticsEnabled) {
      Cookies.remove(ANALYTICS);
      removeAnalyticsScript();
    } else {
      Cookies.set(ANALYTICS, 'enabled');
      injectAnalyticsScript(googleAnalyticsId);
    }

    window.location.reload();
  };

  const handleToggleAnalytics = () => {
    setAnalyticsEnabled(!analyticsEnabled);
  };

  return (
    <>
      <Billboard title={t('public.cookie_settings.title')} className="billboard-with-content-padding-bottom" />

      <Container>
        <Row>
          <Col lg={5} className="mx-auto mt-10">
            <div className="custom-control custom-checkbox">
              <input id={'essential-cookies-row-1'} type="checkbox" checked disabled className="custom-control-input" />
              <label className="custom-control-label h5" htmlFor="essential-cookies-row-1">{t('public.cookie_settings.essential_cookies')}</label>
            </div>
            <i className="d-inline-block mt-4 mb-5">{t('public.cookie_settings.essential_cookies_content')}</i>

            <div className="custom-control custom-checkbox">
              <input id={'analytics-cookies-row-2'} type="checkbox" className="custom-control-input" checked={analyticsEnabled} onChange={handleToggleAnalytics} />
              <label className="custom-control-label h5" htmlFor="analytics-cookies-row-2">{t('public.cookie_settings.analytics_cookies')}</label>
            </div>
            <i className="d-inline-block mt-4 mb-5">{t('public.cookie_settings.analytics_cookies_content')}</i>

            <Button onClick={handleSaveSettings} className="w-25 mb-6">
              {t('labels.save')}
            </Button>

            <p>
              {t('public.cookie_settings.additional_information')}
              <a href={`/${i18n.language}${URL.COOKIE_POLICY}`} target="_blank" rel="noreferrer">{t('public.labels.cookie_policy')}</a>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CookieSettingsView;
