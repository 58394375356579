import React, { FC } from 'react';
import { Container } from 'react-bootstrap';

import If from 'bundles/components/common/If/index';
import { BillboardPropsType } from './types';

import './styles.scss';

const Billboard: FC<BillboardPropsType> = ({ title, subTitle, className = '', children }) => {
  return (
    <div className={`billboard ${className} pb-10`.trim()}>
      <Container className="billboard-content text-center">
        <If condition={!children}>
          <h1>
            {title}
          </h1>
          <p className="m-0">{subTitle}</p>
        </If>
        <If condition={children}>
          {children}
        </If>
      </Container>
    </div>
  );
};

export default Billboard;
