import React, { FC } from 'react';
import { faPlus, faMinus } from '@fortawesome/pro-solid-svg-icons';
import { Card, Accordion } from 'react-bootstrap';

import ContextAwareToggle from 'bundles/components/common/Accordion/ContextAwareToggle';

import { AccordionItem as AccordionItemTypes } from './types';

import './styles.scss';

const AccordionItem: FC<AccordionItemTypes> = ({ title, eventKey, children }: AccordionItemTypes) => {
  return (
    <Card className="mb-3">
      <ContextAwareToggle as="div" className="card-header" classNameIcon="text-primary" eventKey={eventKey} icons={[faMinus, faPlus]}>
        {title}
      </ContextAwareToggle>

      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body className="py-5 px-7">
          {children}
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

export default AccordionItem;
