import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const TransactionsAndPayments: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.transactions_and_payments.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.transactions_and_payments.purchase_transactions.title')}</h3>
          <p>{t('public.faq.transactions_and_payments.purchase_transactions.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.transactions_and_payments.merchant_not_received_amount.title')}</h3>
          <p>{t('public.faq.transactions_and_payments.merchant_not_received_amount.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.transactions_and_payments.transaction_status_appears_refunded.title')}</h3>
          <p>{t('public.faq.transactions_and_payments.transaction_status_appears_refunded.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.transactions_and_payments.description_indicated_transaction_was_adjusted.title')}</h3>
          <p>{t('public.faq.transactions_and_payments.description_indicated_transaction_was_adjusted.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.transactions_and_payments.declined_transaction.title')}</h3>
          <p>{t('public.faq.transactions_and_payments.declined_transaction.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.transactions_and_payments.check_transaction_status.title')}</h3>
          <p>{t('public.faq.transactions_and_payments.check_transaction_status.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const TransactionsAndPaymentsWrapper: FC<{eventKey: string}> = props => <TransactionsAndPayments {...props} />;

export default TransactionsAndPaymentsWrapper;
