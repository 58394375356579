import React, { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';

import Billboard from 'bundles/components/common/public/Billboard';
import JoinVipClub from 'bundles/components/common/public/JoinVipClub';
import { URL } from 'bundles/constants';
import { publicImages } from 'bundles/utils';

import './styles.scss';

const PersonalPageView = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Billboard className="billboard-personal">
        <Row>
          <Col lg={5}>
            <Billboard.Title
              title={t('public.personal.billboard.title')}
              subTitle={t('public.personal.billboard.sub_title')}
              btnLabel={t('public.labels.get_started')}
              btnUrl={`/${i18n.language}${URL.CONSUMERS_NEW}`}
            />
          </Col>
          <Col lg={7}>
            <img className="billboard-image" alt="deposit mobile screen" src={publicImages.depositMobileScreen} />
          </Col>
        </Row>
      </Billboard>

      <Container>
        <section>
          <h2>{t('public.personal.personal_benefits.title')}</h2>
          <Row>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for saving money" src={publicImages.money} />
              <h3 className="mt-2 mb-1">{t('public.personal.personal_benefits.save_money.title')}</h3>
              {t('public.personal.personal_benefits.save_money.content')}
            </Col>
            <Col lg={6} xl={4} className="mb-6 mb-xl-0">
              <img className="feature-icon" alt="icon for shopping" src={publicImages.wallet} />
              <h3 className="mt-2 mb-1">{t('public.personal.personal_benefits.shop_whenever.title')}</h3>
              {t('public.personal.personal_benefits.shop_whenever.content')}
            </Col>
            <Col lg={6} xl={4}>
              <img className="feature-icon" alt="icon for security" src={publicImages.checkWithShield} />
              <h3 className="mt-2 mb-1">{t('public.personal.personal_benefits.stay_protected.title')}</h3>
              {t('public.personal.personal_benefits.stay_protected.content')}
            </Col>
          </Row>
        </section>
      </Container>

      <Container fluid className="billboard-aside billboard-aside-right billboard-aside-fees-and-limits bg-secondary">
        <Container>
          <section>
            <Row className="justify-content-md-between">
              <Col lg={5} className="py-0 py-xl-10">
                <h2>{t('public.personal.fees_and_limits.title')}</h2>
                <p>{t('public.personal.fees_and_limits.content')}</p>
                <a className="btn btn-primary register-btn" href={`/${i18n.language}${URL.FEES_LIMITS}`}>{t('public.labels.find_out_more')}
                  <FontAwesomeIcon className="ml-2" icon={faArrowRightLong} role="button" />
                </a>
              </Col>
              <Col lg={6}>
                <div className="d-flex flex-wrap billboard-aside-image-wrapper">
                  <img alt="eZeeWallet mobile app screens" className="position-absolute" src={publicImages.phoneTransferView} />
                </div>
              </Col>
            </Row>
          </section>
        </Container>
      </Container>

      <Container fluid className="billboard-aside billboard-aside-left bg-lighter-gray">
        <Container>
          <section>
            <JoinVipClub />
          </section>
        </Container>
      </Container>
    </>
  );
};

const PersonalPageViewWrapper: FC = () => <PersonalPageView />;

export default PersonalPageViewWrapper;
