import { FieldValues, UseFormGetValues } from 'react-hook-form';

import { URL } from 'bundles/constants';
import axios from 'bundles/apis/defaults';
import { buildFormData } from 'bundles/utils';

export const setDobDisabled = (getValues: UseFormGetValues<FieldValues>) => !getValues('consumer[address_attributes][country_id]');

export const setSubmitRequest = (isMerchantInitiated: boolean | undefined, merchantInitiatedId: string, f: FieldValues, searchParams: string) => {
  if(isMerchantInitiated) {
    const usp = new URLSearchParams(searchParams);
    return axios.patch(`${URL.CONSUMERS_REGISTRATIONS}/${merchantInitiatedId}`,  buildFormData({ ...f, token: usp.get('token') }));
  } else {
    return axios.post(URL.CONSUMERS_REGISTRATIONS, buildFormData(f));
  }
};
