import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';

import { publicImages } from 'bundles/utils';
import { URL } from 'bundles/constants';

import './styles.scss';

const JoinVipClub: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Row>
        <Col lg={6} className="position-relative order-lg-1 order-2 join-vip-club-image-wrapper">
          <img className="position-absolute" alt="eZeeWallet VIP club" src={publicImages.phoneVip} />
        </Col>
        <Col lg={6} className="pt-3 pb-6 pb-lg-10 order-lg-2 order-1">
          <div className="w-75">
            <h2 className="mt-lg-6">{t('public.sections.join_vip_club.title')}</h2>
            <p>{t('public.sections.join_vip_club.content')}</p>
            <a className="btn btn-primary register-btn" href={`/${i18n.language}${URL.CONSUMERS_NEW}`}>{t('public.labels.create_account')}
              <FontAwesomeIcon className="ml-2" icon={faArrowRightLong} role="button" />
            </a>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default JoinVipClub;
