import React, { FC } from 'react';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard/index';

const PrivacyPolicyView = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="legal-documents">
      <Row>
        <Col sm={12} className="p-0">
          <Billboard title={t('labels.privacy_policy')} className="billboard-with-content-padding-bottom" />
        </Col>

        <Col lg={8} className="mx-auto mt-10 mb-5">
          <h2 className="h3">1. Background</h2>
          <p>1.1 At eZeeWallet (&quot;our&quot;, &quot;us&quot; or &quot;we&quot;), we regard the fair and lawful treatment of personal data as a critical factor in the success of our operations and a key to the maintenance of the confidence that exists between those with whom we deal and ourselves. We, therefore, acknowledge our legal obligations under the General Data Protection Regulation (GDPR) and endorse its requirements.</p>
          <p>1.2 We understand that you are aware of and care about your personal privacy interests, and we take that seriously. ‘You’ in this policy means the private individual who is using or otherwise requesting to use the products and services offered by us either on his/her own account or on behalf of a legal entity. This Privacy policy defines our approach regarding the collection and use of your personal data and sets forth your privacy rights.</p>
          <p>1.3 From time to time, we may develop new or offer additional services, so we may update this Privacy policy accordingly. We recommend that you download or print a copy of this Privacy policy and store it for your records and future reference.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">2. Scope</h2>
          <p>2.1 This policy applies to your Personal Data when you visit our site(s) or use our services and does not apply to online websites or services that we do not own or control.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">3. Data collection</h2>
          <p>3.1 In accordance with the applicable laws and regulations, we only collect and process a limited amount of data, including personal information, about you that is necessary to perform our business operations and provide you with our products and services. Personal data as used in the context of this Privacy policy, applies to any information that identifies or that can be used to identify you as a private individual, such as your name, mobile phone number, email address, date of birth, and possible further information for the purpose of responding to your request or delivering a specific product or service.</p>
          <p>3.2 We may also require other identification information if you send or receive certain high- value transactions or high overall payment volumes through our services or as is otherwise required in order for us to comply with our financial crime prevention obligations under the respectively applicable regulatory requirements.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">4. Types of data</h2>
          <h5 className="text-primary">Information that you voluntarily provide</h5>
          <p className="mb-2">4.1 We collect personal data when you provide it to us:</p>
          <ol>
            <li>Information that you provide by filling in application forms and forms on our website, which includes information provided at the time of registering to use our services, subscribing to our newsletter(s), and reporting a problem.</li>
            <li>Information provided via electronic means of communication, i.e. when you communicate with us, contact our Customer Support teams or respond to a survey, we may keep a record of our correspondence.</li>
            <li>Information regarding an enquiry from you about our products or services.</li>
            <li>Information that you provide in the context of a job application.</li>
          </ol>

          <h5 className="text-primary">Information received from third parties</h5>
          <p className="mb-2">4.2 We may receive your personal data through third parties:</p>
          <ol>
            <li>Information received by one of emerchantpay Group members.</li>
            <li>Information received by the card schemes (e.g. Visa, Mastercard, etc.), credit reference agencies, fraud prevention agencies, government and law enforcement agencies.</li>
            <li>Information received via public sources like company registers and filings.</li>
          </ol>

          <h5 className="text-primary">Transaction information</h5>
          <p>4.3 When you use our payment services we collect information about the transaction, as well as other information associated with the transaction such as amount sent or requested, amount paid for products or services, participants associated with the transaction, merchant information, including information about any funding instruments used to complete the transaction, device information, technical usage data, and geolocation information.</p>

          <h5 className="text-primary">Information collected from your computer or your electronic device by our website</h5>
          <p>4.4 As with most websites, the following non-personal data is routinely collected during visits to our website and use of our online resources. This information may include the name of your internet service provider (IP address), the website that directed you to our website, browser and/or device type, date, time and length of your visit. This information cannot be used to personally identify visitors. When collecting and processing your IP address, the latter will be fully anonymised right after collection by deleting the last three figures. That way we are no longer able to identify you as a person. We will delete the IP address within a 12-month timeframe.As with most websites, the following non-personal data is routinely collected during visits to our website and use of our online resources. This information may include the name of your internet service provider (IP address), the website that directed you to our website, browser and/or device type, date, time and length of your visit. This information cannot be used to personally identify visitors. When collecting and processing your IP address, the latter will be fully anonymised right after collection by deleting the last three figures. That way we are no longer able to identify you as a person. We will delete the IP address within a 12-month timeframe.</p>

          <h5 className="text-primary">Information collected through the use of cookies</h5>
          <p>4.5 Our website(s) uses cookies. You can, at all times, manage website cookies in your browser settings and change these settings by accepting, rejecting, or deleting cookies. For more information, please see our Cookie policy.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">5. Use of personal information</h2>
          <p className="mb-2">5.1 We collect, store and process your personal data as described hereunder, primarily to provide you with a safe, smooth, efficient, and customised experience, specifically, for the following business-related purposes:</p>
          <ol>
            <li><strong>Account setup and administration.</strong> Your personal data is required to set up and manage your account, authenticate your access to an account, communicate with you about your account, provide technical and customer support, verify your identity, and send important account, transaction and service information.</li>
            <li><strong>Provision of products and services.</strong> To ensure our customers are genuine and that we are paid for the products and services we provide, we use your personal data for identification and verification purposes, i.e. to confirm your identity and conduct appropriate due diligence checks.</li>
            <li><strong>Contractual obligations.</strong> We need your personal data in order to carry out our obligations arising from any contracts entered into between you and us.</li>
            <li><strong>For our legitimate interests.</strong> We may use your personal data to manage our business needs, such as monitoring, analysing, and improving the services, the sites’ performance and functionality, and to enforce the terms under which we provide our services.</li>
            <li><strong>Surveys and polls.</strong> If you participate in a survey or a poll, the information you provide to us may be used for market and historical research purposes.</li>
            <li><strong>Email alerts.</strong> Where you have explicitly consented, we use your personal data to provide you with information about our products and services, to inform you about important changes to this Privacy policy or other relevant business news.</li>
            <li><strong>Job applications.</strong> If you wish to apply for an advertised vacancy, we need your personal data in order to administer the recruitment process.</li>
            <li><strong>Legal obligations.</strong> We may be required to collect and retain personal data about you for legal and compliance reasons, i.e. to manage risk, or to detect, prevent, and/or remediate fraud or other potentially illegal or prohibited activities.</li>
          </ol>
          <p>5.2 Consistent with our commitment to protect your personal data, we only conduct such processing where a valid lawful basis exists. In particular, we use your personal data in line with our contractual and/or legal obligation, or when we have a legitimate interest. Where our reason for processing is based on your consent, you may withdraw that consent at any time.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">6. Security of personal information</h2>
          <p>6.1 We are committed to make sure your information is protected. Once we receive your information, we use various security features and strict procedures, taking into account industry standards, to ensure the privacy and confidentiality of data and personally identifiable information. We maintain physical, technical and administrative safeguards that comply with regulatory requirements. Specifically, we use a combination of firewall barriers, data encryption techniques and authentication procedures to prevent unauthorised access to your data and to our systems. We also enforce physical access controls to our buildings and files. We test our systems regularly and also contract with outside companies to audit and test our security systems and processes.</p>
          <p>6.2 We authorise access to your personal data only for those employees who require it to fulfil their job responsibilities and provide benefits, goods or services to you. We educate our employees about the importance of confidentiality and maintaining the privacy and security of personal data.</p>
          <p>6.3 Our security procedures are constantly revised based on new technological developments in order to ensure the highest level of protection of your personal information. However, there are also a number of things that you can help with. We encourage you to use a strong password and make sure it is not the same one for all your accounts. You may not share it with anyone. Our representatives will never ask you for your password, so any e-mail or other communication containing such request should be treated as unauthorised or suspicious and forwarded to <a href="mailto:dpo@ezeewallet.com" target="_blank" rel="noreferrer">dpo@ezeewallet.com</a>.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">7. Data storage and retention</h2>
          <p>7.1 We take appropriate technical and organisational measures, both at the time of the design of the processing system and at the time of the processing itself, in order to maintain security and prevent unauthorised processing. We adhere to internationally recognised security standards and our information security management system has been independently certified as complying with the requirements of ISO/IEC 27001:2013 and PCI DSS Level 1.</p>
          <p>7.2 Your personal data will be retained for the minimum amount of time necessary to fulfil our legal or regulatory obligations and for our business purposes. We may retain Personal Data for longer periods than required by applicable regulatory requirements if it is in our legitimate business interests and not explicitly prohibited.</p>
          <p>7.3 If your Agreement is terminated and/or your Account is closed, we may take steps to mask personal data and other information, but we reserve our ability to retain and access the data for so long as required to comply with applicable regulatory requirements. We will continue to use and disclose such personal data in accordance with this Privacy policy.</p>
          <p>7.4 The cookies we use have defined expiration times; unless you visit our Sites or use our Services within that time, the cookies are automatically disabled and retained data is deleted. Please consult our Cookie policy for more information.</p>
          <p>7.5 When we no longer need personal information, we securely delete or destroy it.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">8. Data storage and retention</h2>
          <p>8.1 In order to provide our services, some of the information we collect may be required to be disclosed to our subsidiaries and affiliates or other entities.</p>
          <p>8.2 As a payment service provider, we work with numerous third parties in order to perform our operations in the most efficient way. We may therefore disclose personal data to those entities in order to respond to your requests or inquiries, or when necessary to fulfil the services they provide to us such as software, system, and platform support, cloud hosting services, etc.</p>
          <p>8.3 We partner with other financial institutions to jointly create and offer products and services. We may, therefore, disclose personal data to those entities in order to ensure provision of those products and services to you.</p>
          <p>8.4 We may share information with the other participants to your transactions, including other users you are sending or receiving funds from, and merchants or their service providers when you use the services to pay for products or services. The information may include personal data necessary to facilitate the transaction; personal data to help other participant(s) resolve disputes and detect and prevent fraud; and anonymous data and performance analytics to help merchants better understand the uses of our services and to help merchants enhance users’ experiences.</p>
          <p>8.5 We may also share personal data when we are required to comply with legal obligations and respond to requests from government agencies. This includes exchanging data with other organisations for the purpose of fraud prevention and anti- money laundering measures.</p>
          <p>8.6 We may share your personal data if we believe it’s reasonably necessary to protect the rights, property and safety of our company or its customers.</p>
          <p>8.7 We do not collect or compile personal data for dissemination, rent or sale to external parties for their marketing purposes without your explicit consent.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">9. Cross border transfers of data</h2>
          <p>9.1 We are committed to adequately protecting your personal data regardless of where it resides and to providing appropriate safeguards for your data where the latter is transferred to recipients located outside of the EEA.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">10. Rights of data subjects</h2>
          <p className="mb-2">10.1 Whenever we process your personal data, we take reasonable steps to ensure that it is kept accurate and up to date for the purposes for which it was collected. With respect to the information related to you that ends up in our possession, and recognising that it is your choice to provide it to us, we commit to giving you the ability to do all of the following:</p>
          <ol>
            <li>Right to be informed. You have the right to be informed about the processing (collection and use) of your personal data.</li>
            <li>Right to access. You have the right to obtain confirmation whether or not we are processing personal data about you. You may also request information about: the purpose of the processing; the categories of personal data concerned; who else within our group might have received the data; and how long it will be stored.</li>
            <li>Right to correction. You have the right to review and amend the record of personal data maintained by us if you believe it may be out of date or inaccurate.</li>
            <li>Right to ‘be forgotten’. You may request that we erase your personal data if such processing is not reasonably required for a legitimate business purpose as described in this policy or our compliance with law.</li>
            <li>Right to restrict processing. You have the right to restrict the processing of your personal data, e.g. to limit the way that we use your data.</li>
            <li>Right to portability. When technically feasible, we will, upon request, provide your personal data to you or transmit it directly to another data controller.</li>
            <li>Right to opt-out. You can opt-out of receiving electronic marketing materials from us at any time. This can be done through your account settings, by clicking the ‘unsubscribe’ link in any email communications which we might send to you, or by contacting our DPO. Please note that this might take a few days.</li>
            <li>Right to withdraw consent. You are entitled to withdraw your consent to the processing of your data.</li>
            <li>Right to complain. You have the right to lodge a complaint with the appropriate supervisory authority if you have concerns about how we process your personal data.</li>
          </ol>
          <p>10.2 Reasonable access to your personal data will be provided at no cost within a month upon receiving your request to the DPO at <a href="mailto:dpo@ezeewallet.com" target="_blank" rel="noreferrer">dpo@ezeewallet.com</a>. If access cannot be provided within the aforementioned time frame, we will provide you with the exact date when the information will be provided.</p>
          <p>10.3 We reserve the right to charge you a GBP10.00 fee for administrative costs if your requests are manifestly unfounded or excessive, in particular because of their repetitive character. In accordance with our security procedures we may occasionally request proof of identity before we disclose personal data to you.</p>
          <p>10.4 Please bear in mind that there may be legal or other reasons why we cannot, or are not obliged to, fulfil a request to exercise your rights. We will confirm what they are if that is the case.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">11. Children’s personal data</h2>
          <p>11.1 Our websites and our services are not directed to children under the age of majority. We do not knowingly collect information, including personal data, from children or other individuals who are not legally able to use our sites and services. If we obtain actual knowledge that we have collected personal data from a child under the age of majority, we will promptly delete it, unless we are legally obligated to retain such data. Contact us if you believe that we have mistakenly or unintentionally collected information from a child under the age of majority.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">12. Data Protection Officer</h2>
          <p>12.1 We understand that you may have questions or requests regarding this Privacy policy or your personal information. The latter are welcomed and should be addressed to our Data Protection Officer at <a href="mailto:dpo@ezeewallet.com" target="_blank" rel="noreferrer">dpo@ezeewallet.com</a>.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">13. Review and update</h2>
          <p>13.1 We will only use your personal data in the manner described in this Privacy Policy effective at the time the information was collected. However, we keep this Privacy Policy under regular review and in case of any revisions, the latter will be promptly communicated on this page.</p>
          <p>13.2 We encourage you to periodically review this page to be informed about any changes in how we are protecting your personal data.</p>
          <p>13.3 This Privacy policy was last updated on August 19th, 2022.</p>
        </Col>
      </Row>
    </Container>
  );
};

const PrivacyPolicyViewWrapper: FC = () => <PrivacyPolicyView />;

export default PrivacyPolicyViewWrapper;
