import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Currencies: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.currencies.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.currencies.available_currency.title')}</h3>
          <p>{t('public.faq.currencies.available_currency.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.currencies.can_change_currency.title')}</h3>
          <p>{t('public.faq.currencies.can_change_currency.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.currencies.can_have_multiple_currencies.title')}</h3>
          <p>{t('public.faq.currencies.can_have_multiple_currencies.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const CurrenciesWrapper: FC<{eventKey: string}> = props => <Currencies {...props} />;

export default CurrenciesWrapper;
