import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const AccountRelated: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.account_related.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.account_related.how_can_login.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: t('public.faq.account_related.how_can_login.content') }} />
        </li>
        <li>
          <h3>{t('public.faq.account_related.reset_password.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: t('public.faq.account_related.reset_password.content') }} />
        </li>
        <li>
          <h3>{t('public.faq.account_related.email_password_correct_cant_login.title')}</h3>
          <p>{t('public.faq.account_related.email_password_correct_cant_login.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.email_for_account_locked_on_multiple_failed_sign.title')}</h3>
          <p>{t('public.faq.account_related.email_for_account_locked_on_multiple_failed_sign.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.forgotten_login_email.title')}</h3>
          <p>{t('public.faq.account_related.forgotten_login_email.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.lost_access_to_email_address.title')}</h3>
          <p>{t('public.faq.account_related.lost_access_to_email_address.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.account_limits.title')}</h3>
          <p>{t('public.faq.account_related.account_limits.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.transaction_limits.title')}</h3>
          <p>{t('public.faq.account_related.transaction_limits.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.increase_account_amount_limits.title')}</h3>
          <p>{t('public.faq.account_related.increase_account_amount_limits.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.activate_account.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: t('public.faq.account_related.activate_account.content') }} />
        </li>
        <li>
          <h3>{t('public.faq.account_related.deactivate_account.title')}</h3>
          <p>{t('public.faq.account_related.deactivate_account.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.account_has_been_locked.title')}</h3>
          <p>{t('public.faq.account_related.account_has_been_locked.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.account_has_been_closed.title')}</h3>
          <p>{t('public.faq.account_related.account_has_been_closed.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.verify_account.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: t('public.faq.account_related.verify_account.content') }} />
        </li>
        <li>
          <h3>{t('public.faq.account_related.unable_to_make_deposit.title')}</h3>
          <p>{t('public.faq.account_related.unable_to_make_deposit.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.no_verification_email.title')}</h3>
          <p>{t('public.faq.account_related.no_verification_email.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.locate_account_number.title')}</h3>
          <p dangerouslySetInnerHTML={{ __html: t('public.faq.account_related.locate_account_number.content') }} />
        </li>
        <li>
          <h3>{t('public.faq.account_related.update_home_address.title')}</h3>
          <p>{t('public.faq.account_related.update_home_address.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.account_related.name_registered_incorrectly.title')}</h3>
          <p>{t('public.faq.account_related.name_registered_incorrectly.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const AccountRelatedWrapper: FC<{eventKey: string}> = props => <AccountRelated {...props} />;

export default AccountRelatedWrapper;
