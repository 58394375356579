import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Security: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.security.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.security.how_safe_is_ezeewallet.title')}</h3>
          <p>{t('public.faq.security.how_safe_is_ezeewallet.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.security.personal_information.title')}</h3>
          <p>{t('public.faq.security.personal_information.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.security.protect_against_fraudulent_transactions.title')}</h3>
          <p>{t('public.faq.security.protect_against_fraudulent_transactions.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.security.account_suspended_for_security_reasons.title')}</h3>
          <p>{t('public.faq.security.account_suspended_for_security_reasons.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.security.scam_message.title')}</h3>
          <p>{t('public.faq.security.scam_message.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

export default Security;
