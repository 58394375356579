import React, { FC } from 'react';
import { Col, Row, Container, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard/index';
import { URL } from 'bundles/constants';

const ConsumerTermsConditionsView = () => {
  const { t } = useTranslation();

  return (
    <Container fluid className="legal-documents">
      <Row>
        <Col sm={12} className="p-0">
          <Billboard title={t('public.labels.consumer_terms_and_conditions')} className="billboard-with-content-padding-bottom" />
        </Col>

        <Col lg={8} className="mx-auto mt-10 mb-5">
          <h2 className="h3">1. Definitions and interpretations</h2>
          <h5 className="text-primary">In the Agreement, the following definitions will apply unless the context otherwise requires:</h5>
          <Table bordered>
            <tbody>
              <tr>
                <td><strong className="no-wrap">Balance:<br />Available Balance</strong></td><td>represents the amount of E-Money available on your EZW account that you can use for payments, subject to the terms of these {'T&C\'s'}</td>
              </tr>
              <tr>
                <td><strong>E-Money</strong></td><td>means electronically stored monetary value issued on receipt of funds for the purpose of making payment transactions</td>
              </tr>
              <tr>
                <td><strong>EZW Credentials</strong></td><td>means the email address and the password you use to login into your EZW account</td>
              </tr>
              <tr>
                <td><strong>Financial Liabilities</strong></td><td>means any claims, losses, liabilities, penalties, costs, expenses, charges or damages (including reasonable legal fees)</td>
              </tr>
              <tr>
                <td><strong>Limits</strong></td><td>means any limitation to deposit, receipt, store or withdraw amounts to or out of your EZW account</td>
              </tr>
              <tr>
                <td><strong>Merchant</strong></td><td>means a legal entity that conducts sales of products/goods or ser- vices electronically and receives payments via eZeeWallet based on Transactions that occur at their eCommerce website</td>
              </tr>
              <tr>
                <td><strong>Non-Supported Country</strong></td>
                <td>means a country to which eZeeWallet does not provide Service. The list of Non-Supported Countries is available on <a href="/">www.eZeeWallet.com</a> and is subject to changes</td>
              </tr>
              <tr>
                <td><strong>Party</strong></td><td>each party under the Agreement – eZeeWallet and the Consumer - may be individually referred as a “Party”</td>
              </tr>
              <tr>
                <td><strong>Payment</strong></td><td>the transfer of E-money from a payor to a payee</td>
              </tr>
              <tr>
                <td><strong>Payment Card</strong></td><td>means credit card or debit card</td>
              </tr>
              <tr>
                <td><strong>PSP or Payment Service Provider</strong></td><td>any provider of services that enables payments</td>
              </tr>
              <tr>
                <td><strong>Regulatory Requirements</strong></td><td>means any law, statute, regulation, order, judgement, decision, recommendation, rule, policy or guideline passed or issued by parliament, government or any competent court</td>
              </tr>
              <tr>
                <td><strong>Security Information</strong></td><td>as defined in art. 8.1. of eZeeWallet {'T&C\'s;'} means all Credentials, IDs (username), passwords, or any other data that you use to access your account</td>
              </tr>
              <tr>
                <td><strong>Service,<br />our Service,<br />EZW Service</strong></td><td>issuance of E-money and provision of services closely related to the issuance of E-money that enables you to make payments and to receive payments from third parties</td>
              </tr>
              <tr>
                <td><strong>Transaction</strong></td><td>means, as the context permits: deposits, receipts, payments or refunds (withdrawals)</td>
              </tr>
              <tr>
                <td><strong>Transaction:<br />Pay-in Transaction</strong></td><td>represents funds received in your account</td>
              </tr>
              <tr>
                <td><strong>Transaction:<br />Payout Transaction</strong></td><td>represents funds withdrawn from your account based on your authorisation</td>
              </tr>
              <tr>
                <td><strong>We, Us, Our</strong></td><td>{'means the company which provides the Service to you as defined in art. 17.1 of eZeeWallet T&C\'s'}</td>
              </tr>
              <tr>
                <td><strong>Standards</strong></td><td>means all applicable rules, regulations and operating guidelines issued by the Card Schemes as updated from time to time</td>
              </tr>
            </tbody>
          </Table>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">1. Getting started</h2>
          <p>1.1 These Terms and Conditions (hereinafter {'"T&C\'s"'}) are a contract between you and eZeeWallet (hereinafter referred to a {'"'}<strong>EZW</strong>{'"'}). They apply to your EZW account in addition to the Terms of Use of <a href="/">eZeeWallet.com</a>, our Privacy Policy and our Cookie Policy. Please read carefully all these legally binding documents and keep a copy or download a copy of them for your records and future reference.</p>
          <p>1.2 By using your EZW account, you shall be deemed to have accepted and fully understood these {'T&C\'s'}, our Terms of Use of <a href="/">eZeeWallet.com</a>, our Privacy Policy and our Cookie Policy, and you agree to comply with them in your use of the EZW account.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">2. Definitions and integration</h2>
          <h5 className="text-primary">EZW account</h5>
          <p>2.1 Your EZW account is a <strong>Personal Account</strong> into which you load funds in the form of E-Money, and you can utilise it in accordance with these {'T&C\'s'}.</p>
          <p>2.2 Your EZW account is not a bank account and we do not act as a trustee, fiduciary or escrow holder in respect of the Balance in your EZW account.</p>
          <p>2.3 You may not open multiple accounts in your name. Unless, with our prior approval, we may allow you to also hold one business account which will be subject to the EZW business account {'T&C\'s'}.</p>
          <h5 className="text-primary">Apply for account</h5>
          <p className="mb-2">2.4 You can apply for EZW account at any time. In order to apply for EZW account you must:</p>
          <ol>
            <li>be at least eighteen (18) years old, or the age of majority, whichever is older, in your country of residence;</li>
            <li>have full legal capacity to enter into a contract;</li>
            <li>not be a resident of a Non-Supported Country;</li>
            <li>maintain an active residential address, an email address and a mobile phone number;</li>
            <li>satisfactorily pass required validation and verification checks;</li>
            <li>not have had any previous EZW account closed by us or be in breach of EZW {'T&C\'s'}.</li>
          </ol>
          <p className="mb-2">2.5 At the time of your application or at any time in the future, in connection with your EZW account, including for the purposes of preventing financial crimes (such as fraud, money laundering, terrorism financing, bribery and corruption, tax evasion, etc.):</p>
          <ol>
            <li>we may ask you to provide documentary evidence; and</li>
            <li>we may perform electronic identity verification checks directly or using relevant third parties, such as an ID verification service.</li>
          </ol>
          <p>2.6 Your EZW account will not be activated unless we have been provided with such information as we may require enabling us to identify you and comply with all applicable due diligence and anti-money laundering requirements.</p>
          <p>2.7 We shall keep records of such information and documents in accordance with all applicable Regulatory Requirements.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">3. Using Your EZW account</h2>
          <h5 className="text-primary">Account functionalities</h5>
          <p className="mb-2">3.1 By logging into your EZW account, and where such functionality is supported on your EZW account, you will be able to:</p>
          <ol>
            <li>make and receive Transactions;</li>
            <li>access information – check your Available Balance, review your transaction details and transaction history, including any applicable fees or any other lawful offsets associated with your Transactions and account limits;</li>
            <li>provide information – change your password.</li>
          </ol>

          <h5>Make and receive transactions</h5>
          <p>3.2 Your EZW account enables you to make and receive Pay-in and Payout Transactions.</p>

          <h5>Access information</h5>
          <p>3.3 We will provide you with access to information regarding your EZW account in a way that will allow you to store and reproduce this information unchanged, e.g. by printing a copy.</p>
          <p>3.4 You will be able to access information not older than thirty-six (36) months.</p>
          <p>3.5 You will be able to download records of your Transactions and transaction history in electronic format. We will not provide you with paper statements.</p>
          <p>3.6 The information regarding your EZW account will be updated if there has been any activity on your account, including fees incurred or other lawful offsets charged.</p>
          <p>3.7 We may charge you a fee for providing additional information or for providing it in a way different then as described herein.</p>

          <h5>Provide information</h5>
          <p>3.8 You are required promptly to provide information to Customer Support should any changes regarding your contact details occur. Our Customer Support team will update the information accordingly. However, we shall not be liable for any loss, direct or indirect, arising out of, or in connection with, your failure to do so.</p>

          <h5 className="text-primary">Account status</h5>
          <p className="mb-2">The status of your EZW account can be one of the following:</p>
          <ol>
            <li><strong>No restrictions</strong> – fully functional account; you can receive Pay – ins and make Payout Transactions;</li>
            <li><strong>No withdrawal</strong> – account with suspended Payout functionalities; Payout Transactions cannot be made, Pay-in transactions can be received;</li>
            <li><strong>Payments suspended</strong> – no Transactions can be executed; you will be able to log in and observe personal details and account information without being able to edit them;</li>
            <li><strong>No login</strong> – you will not be able to log into your account due to risk, compliance or fraud prevention measures;</li>
            <li><strong>Closed</strong> – account which cannot be returned to active; you will not be able to log into your account anymore.</li>
          </ol>

          <h5 className="text-primary">Usage. Restricted activities</h5>
          <p>3.10 You can use your EZW account if it is activated. Before you initiate any Transaction, you must make sure that your EZW account is active.</p>
          <p>3.11 Your account is considered active if its assigned status is No restrictions.</p>
          <p>3.12 In order to make your account active again, you need to contact Customer Support. The status of your account will be changed to No restrictions the moment the reason for the deactivation of your account is no longer present.</p>
          <p>3.13 You must only use your EZW account for personal purposes and not as a means to trade or run a business, unless with our prior approval. You may not permit any other person to use your EZW account.</p>
          <p>3.14 You shall not make your EZW available to anyone else nor try to sell or transfer anyone else the ability to use it. Regardless, if you allow access to your EZW account to another person(s), you shall be responsible for any transactions initiated by such persons.</p>
          <p className="mb-2">3.15 You must not use our Service in a manner that is not in compliance with the provisions of the applicable Regulatory Requirements, Standards and these {'T&C\'s'}, including not doing any of the following:</p>
          <ol>
            <li>provide false, inaccurate or misleading information or fail to provide us with reasonably required information;</li>
            <li>use an anonymising proxy or access the Service from a Non-Supported Country;</li>
            <li>act in a way that might undermine the credibility of eZeeWallet and/or the payment system as a whole;</li>
            <li>refuse to cooperate in an investigation regarding your EZW account;</li>
            <li>infringe intellectual property rights, rights of publicity or privacy.</li>
          </ol>

          <h5 className="text-primary">Currencies</h5>
          <p>3.16 Your EZW account can be denominated in one currency. You can select it during the registration process.</p>

          <h5 className="text-primary">Transactions</h5>
          <p>3.17 Transaction means as the context permits: Pay-in Transactions and Payout Transactions.</p>
          <p>3.18 Transactions may be restricted by your account type, individual usage patterns and payment risk profiles.</p>
          <p>3.19 For compliance purposes, we are entitled to add or amend particular payment restrictions (limits) without notice and to the extent required to meet the provisions of respective Regulatory Requirements.</p>
          <p>3.20 If your Transaction involves a currency conversion by us, it will be completed at a foreign exchange rate determined by a financial institution, which is adjusted regularly based on market conditions. Foreign exchange fee applies.</p>

          <h5 className="text-primary">Available balance</h5>
          <p>3.21 The balance on your EZW account represents the available amount of E-Money and will be referred to as Available Balance in these {'T&C\'s'}. You will not receive interest or any other earnings on your Available Balance.</p>
          <p>3.22 If for any reason a Payout Transaction is processed, and the transaction amount exceeds your Available Balance, you must repay us the amount of such excess immediately.</p>
          <p>3.23 Where the aggregated amount you owe us exceeds the Available Balance held on your EZW account, we may show your balance in negative figures as a reflection of the net amount you owe to us.</p>
          <p>3.24 You agree that we may set-off the Available Balance held on your EZW account with any applicable fees or any other lawful offsets that you owe us.</p>
          <p>3.25 We shall be entitled to suspend the payout functionality of your account until you repay us all due amounts.</p>
          <p>3.26 The Available Balance on your EZW account represents the amount of E-Money that you can use for Payout Transactions. Your Available Balance may be at zero and your EZW account will remain open and functional.</p>
          <p>3.27 If you make a Payout Transaction using the Available Balance on your EZW account, the Available Balance must be sufficient to cover the amount of the Transaction, including any applicable fees or any other lawful offsets (e.g. value added tax and any other taxes). We will not execute your Payout Transaction if you do not have sufficient Available Balance.</p>
          <p>3.28 We may suspend any of your Payout Transactions at our reasonable discretion considering the risks associated with the respective Transaction such as financial crime, security breach, fraud, insufficient funds, etc.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">4. Fund Your EZW account</h2>
          <h5 className="text-primary">Initial funding</h5>
          <p>4.1 You may fund your EZW account by purchasing E-money from us against payment of an equivalent amount made one of the funding methods listed as available on the EZW website.</p>

          <h5 className="text-primary">Funding limits</h5>
          <p>4.2 The Pay-in (funding) Transactions are subject to Limits. We are entitled to vary the Limits at any time and to decline any Pay-in Transaction at any time.</p>

          <h5 className="text-primary">Additional provisions</h5>
          <p>4.3 To secure your performance of these {'T&C\'s'}, you grant us a legal claim against the Available Balance on your EZW account as security for any amount you may owe to us. We may also recover amounts you owe us through legal means, including, without limitation, through the use of a debt collection agency.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">5. Receive payments</h2>
          <p>5.1 We will allow Merchant(s) with an EZW business account to initiate payment(s) to your EZW private account.</p>
          <p>5.2 Your EZW account can also be funded with person-to-person fund transfers. You are able to receive payment(s) from other private individuals.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">6. Make payments</h2>
          <h5 className="text-primary">General provisions</h5>
          <p>6.1 The funds in your EZW account shall always be available to you for the purpose of enabling Payout Transactions.</p>
          <p>6.2 You can use your EZW account to pay online for goods or services at any virtual location that displays the acceptance mark of eZeeWallet.</p>
          <p>6.3 When you make a payment, you authorise us to transfer funds (E-Money) from your Available Balance to the account of the respective Merchant.</p>
          <p className="mb-2">6.4 All payments made using your EZW account are subject to:</p>
          <ol>
            <li>validity of the provided payment details;</li>
            <li>sufficiency of Available Balance on your EZW account;</li>
            <li>payment limits imposed on you as a payer and to the Merchant as a payee.</li>
          </ol>
          <p>6.5 Some Merchants may not accept payment using an EZW account. It is your responsibility to check with each Merchant what its policy is. We accept no liability if a Merchant refuses to accept payment using an EZW account.</p>

          <h5 className="text-primary">Authorisation</h5>
          <p>6.6 Each Payout Transaction must be authorised by you by entering your EZW credentials when required. You cannot withdraw your authorisation after having completed your payment instruction.</p>
          <p>6.7 After you authorise your Payout Transaction, we will reduce your Available Balance with the amount of the Transaction plus any applicable fees or any other lawful offsets associated with it.</p>

          <h5 className="text-primary">Execution</h5>
          <p className="mb-2">6.8 We will execute your payment subject to you providing us with:</p>
          <ol>
            <li>your consent (authorisation);</li>
            <li>valid details of the payee (Merchant);</li>
            <li>any other information required for the processing of the payment.</li>
          </ol>
          <p>6.9 We may refuse to execute a Transaction which could breach these {'T&C\'s'} or if we have reasonable grounds to suspect that you or any third party has committed or are planning to commit fraud or any other illegal or non-permitted use of our Service.</p>
          <p>6.10 You agree that you will not hold us liable for any damages resulting from a payee’s decision to decline your payment made via EZW service. In all cases of decline, we will return the amount of the payment to your Available Balance.</p>
          <p className="mb-2">6.11 The executed Payout Transactions are final and are irreversible save in the following circumstances and at our reasonable discretion:</p>
          <ol>
            <li>where we confirm there has been a Merchant error;</li>
            <li>where there is illegal activity involving your account; or</li>
            <li>where you are in breach of these {'T&C\'s'}.</li>
          </ol>

          <h5 className="text-primary">Unauthorised transactions. Limitation of Liability</h5>
          <p>6.12 A transaction shall be considered unauthorised if you have not genuinely given your consent (authorisation) for the transaction to be made.</p>
          <p>6.13 We recommend that you check your transaction history regularly via your EZW account. You must contact Customer Support as soon as possible and notify us if you believe that a transaction has been made without your authorisation.</p>
          <p>6.14 Once we have been notified and in order to limit any further losses, we may suspend your EZW account and suspend our Service in order to prevent further misuse of your account whilst we investigate the misuse further.</p>
          <p>6.15 You will not be held liable for any losses once you have notified us unless we reasonably determine that you have acted fraudulently, in which case you shall be liable for all losses.</p>
          <p>6.16 If our investigation shows that any disputed Transaction was authorised by you, or you have acted fraudulently or with gross negligence (for example by failing to keep your Security Information secure), we may reverse any refund made and you may be liable for any loss eZeeWallet suffers.</p>

          <h5 className="text-primary">Transaction disputes</h5>
          <p>6.17 We are not responsible for the quality, safety, legality or any other aspect of any goods or services purchased via your EZW account.</p>
          <p className="mb-2">6.18 In all cases when you:</p>
          <ol>
            <li>did not receive goods or services that you purchased;</li>
            <li>received incorrect goods or services;</li>
            <li>received goods or services of un-merchantable quality;</li>
            <li>used an EZW account for a purchase from a business that has filed for bankruptcy.</li>
          </ol>
          <p>You should first contact the Merchant to try to resolve the situation.</p>
          <p>6.19 If you can’t come to an agreement with the Merchant and you want to dispute the Transaction, and in all cases when you would like to dispute a charge you need to contact our Customer Support.</p>
          <p>6.20 We have the right to deny your dispute case by explaining our reasons.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">7. Withdrawals</h2>
          <p>7.1 Upon your request, we will execute a withdrawal at any time from your Available Balance.</p>
          <p className="mb-2">7.2 We will perform a withdrawal from your Available Balance less any applicable fees or any other lawful offsets provided that your request is made within the limitation period, as defined in article 7.3 and once:</p>
          <ol>
            <li>we have received all the necessary information from you in order for your account to be considered a verified account with assigned status No restrictions;</li>
            <li>all transactions and applicable fees and other lawful offsets have been processed;</li>
            <li>you have not acted fraudulently or, with gross negligence or in such a way as to give rise to reasonable suspicion of fraud or gross negligence.</li>
          </ol>
          <p>7.3 You may withdraw your Available Balance, less any applicable fees or any other lawful offsets within <strong>six (6) years</strong> after the date of termination of our agreement with you ({'"limitation period"'}).</p>
          <p>7.4 We will execute your withdrawal request from your Available Balance, by transferring the amount to your bank account. We will process your withdrawal within two to four (2-4) business days. It might take up to fourteen (14) business days until your withdrawal is reflected in your bank account.</p>
          <p>7.5 If, following the withdrawal of your Available Balance, any further transactions are found to have been made or fees and/or other lawful offsets incurred using your EZW account or we receive a reversal of any prior funding transaction, we will notify you of the amount and you must immediately repay to us such amount on demand as a debt immediately due.
          </p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">8. Safeguarding measures</h2>
          <h5 className="text-primary">Keeping your account safe</h5>
          <p>8.1 You are responsible for <strong>protecting</strong> your EZW account and for maintaining adequate security and control of any and all, Credentials, IDs (username), password(s) or any other data that you use to access your EZW account ({'"'}<strong>Security Information</strong>{'"'}).</p>
          <p>8.2 You will need your Security Information to use the functionality of your EZW account.</p>
          <p className="mb-2">8.3 You are required not to share your Security Information with anyone and to keep it safe, and separate from your EZW account or any record you keep of it. This includes:</p>
          <ol>
            <li>memorising any Security information as soon as you receive it, and destroying the communication used to transmit it to yous;</li>
            <li>keeping your Security Information private at all times, including not using any Security Information if anyone else is watching;</li>
            <li>not disclosing your Security Information to any person.</li>
          </ol>
          <p>8.4 You are recommended to change your password regularly and to use up to date virus, malware and spyware scanning software and firewall protection to reduce the risk of a security breach.</p>
          <p className="mb-2">8.5 You must contact Customer Support as soon as possible and notify us if:</p>
          <ol>
            <li>keeping your funds separate in our books/records – from the moment the funds are received;</li>
            <li>depositing them in safeguarding account(s), opened with an authorised credit institution, pooled with funds representing the balance of other customers in an account held by us, yet separate from our own accounts.</li>
          </ol>
          <p>8.6 Once we have been notified, we will suspend your EZW account and suspend our Service as soon as possible. For security purposes, we shall ask you to provide us with certain identifying details.</p>
          <p>8.7 You agree to cooperate and provide information that we and any appropriate regulatory authorities may require in relation to the investigation of any unauthorised access to your EZW account and Security Information.</p>
          <p>8.8 Failure to comply with the provisions regarding Security Information may affect your ability to claim any losses should your EZW account be compromised.</p>

          <h5 className="text-primary">Safeguarding your balance</h5>
          <p className="mb-2">8.9 We safeguard the funds representing a balance on your EZW account by applying the segregation method. These include:</p>
          <ol>
            <li>keeping your funds separate in our books/records – from the moment the funds are received;</li>
            <li>depositing them in safeguarding account(s), opened with an authorised credit institution, pooled with funds representing the balance of other customers in an account held by us, yet separate from our own accounts.</li>
          </ol>
          <p>8.10 In case of insolvency of eZeeWallet, your claims as a customer shall be paid from the asset pool formed from the funds in the safeguarding accounts. Only costs in respect of the costs of distributing the asset pool will be paid prior to any customers’ claims.</p>
          <p>8.11 eZeeWallet offers you an E-Money product and although it is a product regulated by the Financial Conduct Authority, it is not covered by any financial services compensation scheme.</p>
        </Col>


        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">9. Suspending an EZW account</h2>
          <p>9.1 Suspending means any temporary termination of our Service where you will be unable to use your EZW account.</p>
          <p className="mb-2">9.2 Your EZW account may be suspended:</p>
          <ol>
            <li>upon your request – e.g. due to the security breach;</li>
            <li>at our reasonable discretion.</li>
          </ol>
          <p>9.3 We may suspend your EZW account for objectively justified reasons relating to the security of your EZW account, the suspicion of unauthorised or fraudulent use of your EZW account. In such cases, we will inform you of the suspension of your account and the reasons for it, where possible, before the suspension and at the latest immediately thereafter, unless providing such information would compromise objectively justified security reasons or is prohibited by Regulatory Requirements.</p>
          <p className="mb-2">9.4 We may suspend your EZW account for such period as may reasonably be required and at any time, without prior notice:</p>
          <ol>
            <li>in the event of any fault or failure in the data information processing system;</li>
            <li>if we reasonably believe that you have used or are likely to use our Service in breach of any provision of these {'T&C\'s'} or to commit any offence;</li>
            <li>if we suspect that you have provided us with false or misleading information;</li>
            <li>if we are not satisfied with any financial crime or other investigations we have undertaken or if we suspect fraud;</li>
            <li>by order or recommendation of any relevant governmental or regulatory body.</li>
          </ol>
          <p>9.5 We shall unsuspend your EZW account once the reasons for the suspension no longer exist and all eZeeWallet requirements are met.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">10. Dormant account</h2>
          <p>10.1 If no transaction, including funding transaction, is made on your EZW account for a period of <strong>six (6)</strong> consecutive <strong>months</strong>, we shall regard that account as dormant.<br />Once your account is considered dormant, the account status Payouts suspended is assigned to it.</p>
          <p>10.2 In order for your account to no longer be in a dormant state, you need to contact Customer Support. The status of your account will be changed to No restrictions the moment the reason for the setting of your account to status Payouts suspended is no longer present.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">11. Limitation liability</h2>
          <p>11.1 We shall only be liable to you for loss or damage caused directly and reasonably foreseeable by our breach of this agreement and our liability in these circumstances is limited as set out in the articles below.</p>
          <p className="mb-2">11.2 To the fullest extent permitted by applicable Regulatory Requirements our total liability under or arising from your agreement with us shall be limited as follows:</p>
          <ol>
            <li>where your EZW account is faulty due to our default, our liability shall be limited to replacement of the account or, at our choice, repayment to you of your Available Balance;</li>
            <li>where sums are incorrectly deducted from your balance due to our fault, our liability shall be limited to payment to you of an equivalent amount; and</li>
            <li>in all other circumstances of our default, our liability will be limited to repayment of the amount of your Available Balance.</li>
          </ol>

          <p className="mb-2">11.3 We shall not be liable:</p>
          <ol>
            <li>if you are unable to use the Service or any of your EZW account functionalities for any fault or failure beyond our reasonable control and which we are unable to overcome by the exercise of reasonable diligence;</li>
            <li>if your EZW account is compromised, unless it is reported to us without undue delay;</li>
            <li>where you have acted fraudulently or with gross negligence for any acts or omissions of third parties, including: if a merchant refuses to accept a payment via eZeeWallet; for the goods or services that are purchased with your EZW account.</li>
          </ol>

          <p>11.4 Nothing in these {'T&C\'s'} shall exclude or limit either party’s liability in respect of death or personal injury arising from that party’s negligence or fraudulent misrepresentation.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">12. Communication</h2>
          <h5>Your contact details</h5>
          <p>12.1 We shall contact you regarding any matter relating to EZW Service using the contact details (your email, postal address and/or mobile phone number) you provide to us. It is up to you to keep your contact details up to date.</p>
          <p>12.2 We will communicate with you electronically. You will be considered to have received a communication from us twenty-four (24) hours after the time we send an email to you.</p>
          <p className="mb-2">12.3 The mobile phone number you have provided is the number to which we and our affiliates may contact you using autodialed or prerecorded calls or text messages to:</p>
          <ol>
            <li>service your accounts;</li>
            <li>investigate or prevent fraud; or</li>
            <li>collect a debt.</li>
          </ol>
          <p>12.4 We will not use autodialed or prerecorded calls or texts to contact you for marketing purposes unless we receive your prior express consent.</p>
          <p>12.5 We may share your mobile phone number with service providers with whom we contract to assist us with the activities listed above, but we will not share your mobile phone number with third parties for their own purposes without your consent.</p>
          <p>12.6 You do not have to agree to receive autodialed or prerecorded calls or texts to your mobile phone number in order to use our Service.</p>
          <p>12.7 You understand and agree that, to the extent permitted by the applicable Regulatory Requirements, we may, without further notice or warning, monitor or record telephone conversations you or anyone acting on your behalf has with us or our agents for quality control and training purposes or for our own protection.</p>
          <p>12.8 You acknowledge and understand that while your communications with us may be overheard, monitored, or recorded not all telephone lines or calls may be recorded by us, and we do not guarantee that recordings of any particular telephone calls will be retained or retrievable.</p>
          <p>12.9 You must notify us immediately of any change in your contact details (your email, postal address and/or phone number(s)) using your EZW account. We may ask to verify your new address and shall request relevant proof. You will be liable for any loss that directly results from any failure to notify us of such changes.</p>

          <h5>Our contact details</h5>
          <p>12.10 You may contact us regarding any matter relating to EZW Service using the {'"'}<a href={URL.REQUEST} target="_blank" rel="noreferrer">Contact Us</a>{'"'} section on the website <a href="/">www.eZeeWallet.com</a>. Unless you are communicating with us about a matter where we have specified another notice address, written notices to eZeeWallet must be sent by postal mail to our operational office in Bulgaria, 1407, Sofia, 14B Filip Kutev Street, 2<sup>nd</sup> Floor.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">13. Changes</h2>
          <p>13.1 We may update or amend these {'T&C\'s'} at any time for any reason. Any changes will be communicated to you via email, sent to the email address you have provided.</p>
          <p>13.2 Any proposed changes to the existing {'T&C\'s'} will be communicated to you no later than two (2) months before the date on which they are to take effect.</p>
          <p className="mb-2">13.3 Changes of the {'T&C\'s'} that do not require prior notice and shall come into effect immediately, if so stated in our change notice to you, are:</p>
          <ol>
            <li>changes that make the {'T&C\'s'} more favourable to you or that have no effect on your rights;</li>
            <li>changes that are necessary for us in order to comply with Regulatory Requirements;</li>
            <li>changes to our charges.</li>
          </ol>

          <p>13.4 We may change the {'T&C\'s'} to make it more favourable to you by upgrading or enhancing the Services we provide to you if there are no increased costs to you.</p>
          <p className="mb-2">13.5 We may also change any of the other terms of the {'T&C\'s'} if the change will have no effect on your rights for any of the following reasons:</p>
          <ol>
            <li>where we reasonably consider that: (i) the change would make the terms easier to understand or fairer to you; or (ii) the change would not be to your disadvantage;</li>
            <li>to cover: (i) the improvement of any service or facility we supply; (ii) the introduction of a new Service or facility; (iii) the replacement of an existing Service or facility with a new one; or (iv) the withdrawal of a Service or facility which has become obsolete, or has not been used by you at any time;</li>
            <li>to enable us to make reasonable changes to the way we provide services as a result of changes in: (i) the banking, investment or financial system; (ii) technology; or (iii) the systems we use to run our business; or</li>
            <li>as a result of a Regulatory Requirement (or where we reasonably expect that there will be a change in a Regulatory Requirement).</li>
          </ol>

          <p className="mb-2">13.6 We may change our charges or introduce a new charge:</p>
          <ol>
            <li>if we provide a new service or facility in connection with Services;</li>
            <li>if there is a change in (or we reasonably expect that there will be a change in: (i) the costs we incur in carrying out the activity for which the charge is or will be made; or (ii) Regulatory Requirements;</li>
            <li>for other valid reasons which are not set out in this clause.</li>
          </ol>

          <p>13.7 Any change or new charge will be a fair proportion, as reasonably estimated by us, of the impact of the underlying change on the costs we incur by providing you Service(s).</p>
          <p>13.8 The exchange rate used to convert foreign currency payments will be a foreign exchange rate determined by a financial institution, which is adjusted regularly based on market conditions.</p>
          <p className="mb-2">13.9 No provision of the {'T&C\'s'} will be deemed waived, altered, modified or amended unless:</p>
          <ol>
            <li>these {'T&C\'s'} provide otherwise; or</li>
            <li>we otherwise agree with you in writing.</li>
            <li>for other valid reasons which are not set out in this clause.</li>
          </ol>

          <p>13.10 The up-to-date version of these {'T&C\'s'} will always be available to you upon request.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">14. Term. Termination</h2>
          <h5 className="text-primary">Term</h5>
          <p>14.1 Our agreement with you starts when you successfully register for an EZW account and ends when your EZW account is closed for whatever reason.</p>
          <p>14.2 The agreement survives termination to the extent and for so long as we require to deal with the closure of your EZW account and to comply with the applicable Regulatory Requirements.The agreement survives termination to the extent and for so long as we require to deal with the closure of your EZW account and to comply with the applicable Regulatory Requirements.</p>

          <h5 className="text-primary">Termination initiated by you</h5>
          <p>14.3 You may terminate your agreement with us at any time by logging in to your EZW account and then following the instructions for account closing.</p>
          <p>14.4 Termination of your contract with us shall be free of charge.</p>
          <p>14.5 You may not close your EZW account to evade an investigation. If you attempt to close your account while we are conducting an investigation, we may hold your funds for up to 180 days to protect us or a third party against any Financial Liabilities. You will remain liable for all obligations related to your account even after the account is closed.</p>

          <h5 className="text-primary">Termination initiated by us</h5>
          <p className="mb-2">14.6 We may terminate our contract with you:</p>
          <ol>
            <li>by providing you with two (2) months’ prior notice;</li>
            <li>at any time where you are in breach of these {'T&C\'s'} and have failed to cure such breach following reasonable notice from us;</li>
            <li>upon request or order of any relevant governmental or regulatory authority;</li>
            <li>in order to comply with the applicable Regulatory Requirements.</li>
          </ol>
          <p>14.7 If we terminate our agreement with you, we will provide you with notice of account closure and where practicable, the reasons for closing your account, together with the ability to withdraw any undisputed funds that we are holding.</p>

          <h5 className="text-primary">Termination consequences</h5>
          <p className="mb-2">Once the agreement is terminated:</p>
          <ol>
            <li>you may request and have the right to receive your balance, less any applicable fees or any other lawful offsets;</li>
            <li>you will remain liable for all outstanding obligations under these {'T&C\'s'} related to your account prior to closure;</li>
            <li>we may retain your balance (in its full amount or partial) for period of ninety (90 days after account closure in order to protect us or a third party against Financial Liabilities.</li>
          </ol>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">15. Redress</h2>
          <p>15.1 The Agreement and any other relationships between the Parties will be governed by the Laws of England and Wales.</p>
          <p>15.2 During your relationship with us, you may wish to make a complaint. For this reason, we have procedures for handling your complaints fairly and promptly.</p>
          <p>15.3 We prefer to receive your complaint in writing so that the details of your complaint are clear and complete. The complaints should be addressed to complaints@ezeewallet. com if you wish to submit a complaint by email; or to Bulgaria, 1407, 14B Filip Kutev Street, 2<sup>nd</sup> Floor, if you wish to submit a complaint by post.</p>

          <p className="mb-2">15.4 Once we have reviewed your complaint, we will provide you with a written response in the form of:</p>
          <ol>
            <li>Summary Resolution Communication, if we have managed to find an appropriate solution to your complaint within a period of three (3) Business Days; or</li>
            <li>Final Response within fifteen (15) Business Days of receiving the complaint in which we may: (1) accept your complaint and, where appropriate, offer redress or remedial action; (2) offer you redress or remedial action without accepting your complaint; (3) reject your complaint giving you our reasons for doing so.</li>
          </ol>
          <p>15.5 We will endeavour to resolve complaints within fifteen (15) Business Days of receiving the complaint, but this will not be possible on all occasions. Where our review exceeds fifteen (15) Business Days, due to reasons beyond our control, we will contact you to inform you of the reasons for the delay, and indicate to you when we expect to be in a position to complete our review, which will not be later than thirty-five (35) Business Days of receiving the complaint.</p>
          <p className="mb-2">15.6 If you are dissatisfied with the manner in which your complaint has been handled or you consider that your concerns have not been fully addressed, you have the right to:</p>
          <ol>
            <li>ask for reconsideration of the response you have received; or</li>
            <li>refer the matter to an external dispute resolution body, such as UK Financial Ombudsman Service (FOS).</li>
          </ol>
          <p>15.7 The FOS is a free, independent service which might be able to settle a complaint between you and us. For further information visit the Financial Ombudsman Service website at: <a href={URL.FINANCIAL_OMBUDSMAN} target="_blank" rel="noreferrer">www.financial-ombudsman.org.uk</a> or other contact options available on the FOS website.</p>
          <p>15.8 All disputes arising out of or in connection with the Agreement, including any question regarding its existence, validity or termination (“Dispute”), may be attempted to be solved through negotiation by the parties within (30) calendar days after either party notifies the other in writing of the existence of the Dispute.</p>
          <p>15.9 Any Dispute not resolved in the above mentioned term shall be finally settled by arbitration under the LCIA Arbitration Rules 2020 by a sole arbitrator appointed in accordance with the said Rules. The seat of arbitration shall be in London, United Kingdom. The language to be used in the arbitral proceedings shall be English.</p>
          <p>15.10 In case of procedural lacunae (gaps) in the LCIA Arbitration Rules 2020, UNCITRAL Model Law on International Commercial Arbitration shall apply.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">16. Fees</h2>
          <p>16.1 The EZW Service we offer under these {'T&C\'s'} will incur event and transaction fees as set out in our Fees and limits section on the website. All fees including foreign exchange fees that we charge may be subject to amendment from time to time.</p>
          <p className="mb-2">16.2 We may charge you event fees, such as:</p>
          <ol>
            <li>funding fee;</li>
            <li>inactivity (dormant account) fee.</li>
          </ol>
          <p>16.3 We may charge you transaction fees, such as currency conversion fees.</p>
          <p>16.4 We will deduct all applicable fees and any other lawful offsets due by you to us from your Available Balance. If there is no Available Balance or the due amount exceeds the Available Balance, you must pay us the excess immediately.</p>
          <p>16.5 Where your transaction involves third-party costs you remain liable for the latter and they will be deducted from your Available Balance or otherwise charged to you. It is your responsibility to check whether any such additional fees apply, as they cannot be refunded once the transaction has been made.</p>
          <p>16.6 Reference to a currency (e.g. EUR or USD or GBP) shall mean that amount or the currency equivalent in which your EZW account is denominated.</p>
        </Col>

        <Col lg={8} className="mx-auto mb-5">
          <h2 className="h3">17. Miscellaneous</h2>
          <h5 className="text-primary">Statutory information</h5>
          <p>17.1 eZeeWallet is the DBA (doing business as) name of emerchantpay ltd., a company organized and existing under the laws of England and Wales and having its registered office at 29 Howard Street, North Shields, Tyne and Wear, NE30 1AR, United Kingdom.</p>
          <p>17.2 emerchantpay ltd. Is duly authorised and regulated by the Financial Conduct Authority under the Electronic Money Regulations 2011 (Reg. No. 900778) for the issuance of electronic money and provision of payment services.</p>

          <h5 className="text-primary">Representations and warranties</h5>
          <p>17.3 You represent and warrant to us that you are not acting on behalf of, or for the benefit of, anyone else.</p>
          <p>17.4 You are solely responsible for understanding and complying with any and all Regulatory Requirements of your jurisdiction that may be applicable to you and your use of EZW Service.</p>

          <h5 className="text-primary">Personal data</h5>
          <p>17.5 We are the data controller for the personal data provided under these {'T&C\'s'} and will process personal data given to us in connection with your EZW account in order to provide you with EZW’s service.</p>
          <p>17.6 We may check your personal data with other organisations and obtain further information about you in order to verify your identity and comply with applicable money laundering and governmental regulations. A record of our enquiries will be left on your file. In accordance with our Privacy Policy and applicable legislation, we may provide personal data supplied by you to certain named third parties (including data processors) for the purpose of performing our obligations and exercising our rights under these {'T&C\'s'}, including third parties located outside the European Economic Area where different data protection standards may apply. We may also disclose your personal data as required by the applicable Regulatory Requirements or any relevant governmental or regulatory authority.</p>
          <p>17.7 We may use your personal data for marketing purposes and for market research purposes, in accordance with applicable legislation and our Privacy Policy.</p>
          <p>17.8 By agreeing to these {'T&C\'s'}, you acknowledge and agree to our processing of your personal data as described hereunder and implied by our Privacy Policy.</p>

          <h5 className="text-primary">No Waiver</h5>
          <p>17.9 Our failure to insist on you strictly complying with the Agreement or any failure or delay on our part to exercise any right or remedy under the Agreement will amount to a waiver of our rights under the Agreement.</p>

          <h5 className="text-primary">Assignment</h5>
          <p>17.10 You may transfer or assign any of your rights or obligations under the Agreement only with our prior written consent which will not be unreasonably withheld.</p>
          <p>17.11 We may transfer any of our rights and obligations under the Agreement to any member our Group or another entity without your consent. You agree that you will promptly complete all documents that we reasonably require in order to make a transfer effective. We may also sub- contract any of our obligations under the Agreement to another person without your prior consent.</p>
          <p>17.12 Where we propose to transfer a material part of our assets to a member of our Group, we may also transfer all of our rights, powers, obligations and liabilities under or in connection with the Agreement without your further consent.</p>

          <h5 className="text-primary">Delegation</h5>
          <p>17.13 We may delegate any of our functions and responsibilities under the Agreement to a member of our Group (with or without a further power to sub-delegate), if we reasonably consider it capable of discharging those functions and responsibilities.</p>
          <p>17.14 Where we delegate or allow sub-delegation: (a)it may be to persons or agents outside the jurisdiction where we provide the services to you; (b) it will not affect our liability to you for the matters delegated; and (c) it will be undertaken in accordance with applicable Regulatory Requirements.</p>
          <p>17.15 We may employ members of our Group and third parties to perform dealing and administrative services that are necessary to enable us to perform the Agreement without further notice or consent.</p>

          <h5 className="text-primary">Severability</h5>
          <p>17.16 Each provision of the Agreement is severable. If any provision of the Agreement or any part of it is or becomes illegal, invalid or unenforcable, the provision will be treated as if it was not in the Agreement. The legality, validity, enforceability and reasonableness of the remainder of the Agreement shall not be affected and shall remain in full force.</p>

          <h5 className="text-primary">Third-party rights</h5>
          <p>17.17 No other person will have any rights to enforce the conditions of the Agreement under or by virtue of the Contracts (Rights of Third Parties) Act 1999, except for any of our Affiliates.</p>

          <h5 className="text-primary">Relationship of the parties</h5>
          <p>17.18 The parties are independent contractors under the Agreement, and nothing herein will be construed to create a partnership, joint venture, agency relationship between them, or any other relationship covered by the Partnership Act 1890. Neither party has authority to enter into agreements of any kind on behalf of the other.</p>

          <h5 className="text-primary">Force Majeure</h5>
          <p>17.19 For the purposes of the Agreement “Force Majeure Event” means any of the following: acts of God, including but not limited to fire, flood, earthquake, windstorm or other natural disasters; epidemic or pandemic; war, threat of or preparation for war, armed conflict, imposition of sanctions, embargo, breaking off of diplomatic relations or similar actions; terrorist attack, civil war, civil commotion or riots; government restrictions, legislative changes or any other cause beyond the reasonable control of the party that affect their performance.</p>
          <p>17.20 Either Party, provided that it adheres to the provisions of clause 17.21, shall not be in breach of this Agreement or liable for any failure or delay in performance of any of its obligations under this Agreement (and, subject to clause 17.22 the time for that Party’s performance of its obligations shall be extended accordingly) arising from our attributable to a Force Majeure Event.</p>
          <p>17.21 If either Party is subject to a Force Majeure Event it shall not be in breach of this Agreement provided that: (1) it promptly notifies the other Party in writing of the nature and extent of the Force Majeure Event causing its failure or delay in performance; (2) it could not have avoided the effect of the Force Majeure Event by taking precautions which, having regard to all the matters known to it before the Force Majeure Event, it ought reasonably to have taken, but did not; and (3) it has used all reasonable endeavour to mitigate the effect of the Force Majeure Event, to carry out its obligations under this Agreement in any way that is reasonably practicable and to resume the performance of its obligations as soon as reasonably possible.</p>
          <p>17.22 If the Force Majeure Event prevails for a continuous period of more than three (3)months, either Party may terminate the Agreement by giving ten (10) Business Days’ written notice to the other. On the expiry of this notice period, the Agreement will terminate. Such termination shall be without prejudice the rights of either Party in respect of any breach of the Agreement occurring prior to such termination.</p>

          <h5 className="text-primary">Entire Agreement</h5>
          <p>17.23 The Agreement constitutes the entire agreement between you and us in respect of its subject matter and supersedes and invalidates all other prior representations, arrangements, understandings and agreements relating to the same subject matter.</p>
          <p>17.24 Each party acknowledges that in entering into the Agreement it does not rely on any statement, representation, warranty or understanding other than those expressly set out in the Agreement.</p>
        </Col>
      </Row>
    </Container>
  );
};

const ConsumerTermsConditionsViewWrapper: FC = () => <ConsumerTermsConditionsView />;

export default ConsumerTermsConditionsViewWrapper;
