import React, { useRef, useEffect } from 'react';

const TrustBox = () => {
  const ref = useRef(null);

  useEffect(() => { window.Trustpilot && window.Trustpilot.loadFromElement(ref.current, true); }, []);

  return (
    <span
      ref={ref}
      className="trustpilot-widget mt-5 mb-1 d-inline-block w-100"
      data-locale="en-GB"
      data-template-id="5419b732fbfb950b10de65e5"
      data-businessunit-id="61b9d707123c27ab3fe99249"
      data-style-height="24px"
      style={{ left: '-1.5rem' }}
      data-theme="dark"
    >
      <a href="https://uk.trustpilot.com/review/ezeewallet.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </span>
  );
};

export default TrustBox;
