import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Fees: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.fees.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.fees.purchases_with_account_balance.title')}</h3>
          <p>{t('public.faq.fees.purchases_with_account_balance.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.fees.fees_to_withdraw.title')}</h3>
          <p>{t('public.faq.fees.fees_to_withdraw.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.fees.fees_to_deposit.title')}</h3>
          <p>{t('public.faq.fees.fees_to_deposit.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.fees.service_monthly_annual_fee.title')}</h3>
          <p>{t('public.faq.fees.service_monthly_annual_fee.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.fees.foreign_exchange_fees.title')}</h3>
          <p>{t('public.faq.fees.foreign_exchange_fees.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const FeesWrapper: FC<{eventKey: string}> = props => <Fees {...props} />;

export default FeesWrapper;
