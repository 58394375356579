import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import AccordionItem from './AccordionItem';

const Deposits: FC<{eventKey: string}> = ({ eventKey }: {eventKey: string}) => {
  const { t } = useTranslation();

  return (
    <AccordionItem title={t('public.faq.deposits.title')} eventKey={eventKey}>
      <ul>
        <li>
          <h3>{t('public.faq.deposits.fund_account.title')}</h3>
          <p>{t('public.faq.deposits.fund_account.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.deposits.deposit_fees.title')}</h3>
          <p>{t('public.faq.deposits.deposit_fees.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.deposits.how_long_does_take_add_funds.title')}</h3>
          <p>{t('public.faq.deposits.how_long_does_take_add_funds.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.deposits.add_funds_with_somebody_else_card_bank_account.title')}</h3>
          <p>{t('public.faq.deposits.add_funds_with_somebody_else_card_bank_account.content')}</p>
        </li>
        <li>
          <h3>{t('public.faq.deposits.can_somebody_else_fund_account.title')}</h3>
          <p>{t('public.faq.deposits.can_somebody_else_fund_account.content')}</p>
        </li>
      </ul>
    </AccordionItem>
  );
};

const DepositsWrapper: FC<{eventKey: string}> = props => <Deposits {...props} />;

export default DepositsWrapper;
