import React, { FC } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Billboard from 'bundles/components/common/public/Billboard/index';

const AffiliateView = () => {
  const { t } = useTranslation();

  return (
    <Container fluid>
      <Row>
        <Col sm={12} className="p-0">
          <Billboard title={t('public.titles.coming_soon')} className="billboard-with-content-padding-bottom" />
        </Col>
      </Row>
    </Container>
  );
};

const AffiliateViewWrapper: FC = () => <AffiliateView />;

export default AffiliateViewWrapper;
